import React, {useContext} from 'react';
import { FlatList,ScrollView,StyleSheet,View, Image, Text, TouchableOpacity, Dimensions,SafeAreaView,Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AuthContext } from '../providers/dataProvider'

import colours from '../config/colours';
import "typeface-muli";

const { height, width } = Dimensions.get('window')
const ServiceProjects = ({title}) => {
    const navigation = useNavigation();
    const {serviceAllData } = useContext(AuthContext);

      const pageDataNew= (serviceAllData.filter(item=>item.categoryname.categoryname.includes(title))).map(projects=>(projects.projects))
      console.log('service page',pageDataNew)

  
    const renderList = ({item}) => {
      const {id, title,location,city,country,client,description,services,category,images} = item
  
      //console.log('items',item)
  
      let newImgURL = Object.keys(images)
      .map((a) => ({sort: Math.random(), value: a}))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value)
      
      var thumbURL = 'thumbURL'+newImgURL[0].slice(newImgURL[0].length-2)
  

    return (
      <TouchableOpacity 
      onPress={() => navigation.navigate('Project Details', {pageData:item})} 
      >

                <View style={styles.itemFlatlist}>
                            <Image  
                              source={{ uri: images[thumbURL]  }} 
                              style={styles.bannerImage} 
                              />              



                        <View style={styles.bannerGradient}>
                        <Text numberOfLines={2} style={styles.bannerText}>{title}{' '}{'\u276F'}</Text>
                        <Text numberOfLines={2}  style={styles.bannerTextSub}>{city},{' '}{country}</Text>

                        </View>


      </View>

      </TouchableOpacity >

    )
  }


    return (
      <SafeAreaView  style={styles.pagestyle}>
        <ScrollView 
        style={styles.headerItem}
        >


                    <FlatList 
                        //horizontal={true}
                        //scrollEnabled={false}
                        data={pageDataNew[0].splice(pageDataNew[0].length-8)}

                        keyExtractor={(item, index) => index.toString()}
                        renderItem={(item,index) => renderList(item,index)}
                        initialNumToRender={10}
                        numColumns={width>1000?4:2}
                        contentContainerStyle={styles.flatlistcontainer}
                        />

        </ScrollView>
      </SafeAreaView>

    );
  };


  export default ServiceProjects;


const styles = StyleSheet.create({
    pagestyle: {
        flex: 1,
    },
    headerItem:
    {
        flex: 1,
        maxWidth: 1000,
        marginTop:2,
    },


    flatlistcontainer: {
      flex: 1,
      alignItems:'center',
      justifyContent:'space-between'
      
      //margin:width>height?10:5,
  },
  itemFlatlist:
    {
        flex: 1,
        //height: width < 1001 ? 200 : 200,
        width:width < 1001 ? 200 : 220,
        alignItems:'center',
        marginTop:20,
        marginHorizontal:5,
        padding:10,
        backgroundColor:colours.primaryBlack,
        borderRadius:10,
    },


    bannerImage: {
        height: width<1000?120:120,
        width: width<1000?150:150,
        resizeMode: 'cover',
        borderRadius:10,

    },


    bannerGradient: {
        flex: 1, 
        //position: 'absolute', 
        //bottom: 0, 

        //alignSelf: 'center'
    },

    bannerText: {
        fontSize: width>1000? '.8rem': '.6rem',
        fontFamily:'muli',
        fontWeight: '800',
        color: colours.brandColor,
        marginTop: 20,
        //paddingStart:15

    },
    bannerTextSub: {
      fontSize: width>1000? '.7rem': '.6rem',
      fontFamily:'muli',
      fontWeight: '800',
      color: colours.primaryGrey,
      marginTop: 5,
      //paddingStart:15

  },
});
  

  
  


