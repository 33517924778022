import React from 'react';
import { DataProvider } from './dataProvider';
import HomeStackScreens from '../pages/homeStackScreens'



export default function Providers() {
  return (
    <DataProvider>
      <HomeStackScreens/>
    </DataProvider>
  );
} 