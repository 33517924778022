//import * as firebase from 'firebase';
import firebase from 'firebase/app'
import '@firebase/auth';
import '@firebase/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCfo9cZERVmiDb86XS9YmnN0cHs2dDqArM",
    authDomain: "pdc-interiors.firebaseapp.com",
    databaseURL: "https://pdc-interiors.firebaseio.com",
    projectId: "pdc-interiors",
    storageBucket: "pdc-interiors.appspot.com",
    messagingSenderId: "754162203554",
    appId: "1:754162203554:web:5492cb7b81a4f021e790fb",
    measurementId: "G-Z7LLK8F5NZ"
  };

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export { firebase };