import React, { useState, useEffect,useContext } from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView } from 'react-native';

import colours from '../config/colours';
import "typeface-muli";
import Header from '../components/header'
import Footer from '../components/footer'
import { useNavigation } from '@react-navigation/native';
import Services from '../components/services';
import { AuthContext } from '../providers/dataProvider'

const { height, width } = Dimensions.get('window')

const ServicesListPage = () => {
  const navigation = useNavigation();

  const {serviceAllData } = useContext(AuthContext);


    return (
      <SafeAreaView>
        <ScrollView>
      <View style={styles.safeView}>

<Header />

<View style={styles.categoryTitle}>
                <Text numberOfLines={1} style={styles.featuredTodayText} >
                      Services
                </Text>
                </View>

<Services serviceAllData={serviceAllData} />



<Footer />
      </View>
      </ScrollView>
      </SafeAreaView>

    );
  };



const styles = StyleSheet.create({
  safeView: {
    flex: 1,    
    maxWidth:width>1000?1000:width,
    minWidth:width>1000? 900:width,
    alignSelf:'center'
  },

  bannerContainer: {
    marginHorizontal: 10,
    paddingVertical: 5,
  },
  listImage: {
    height: width>1000?455:240,
    width: width>1000?990:width-10,
    //borderRadius: 25,
    resizeMode:'cover' ,
    alignSelf:'center'
  },

  listImageHorizontal: {
    height: 200,
    width: 200,
    borderRadius: 25,
    marginHorizontal: 10,
    //marginHorizontal: (widthNew / 25),
    

  },
  flatlistcontainer:
  {
      flex: 1,
      alignItems:'center',

  },

  itemFlatlist:
  {
      flex: 1,
      //height: width < 1001 ? 200 : 200,
      width:width < 1001 ? 200 : 220,
      alignItems:'center',
      marginTop:20,
      marginHorizontal:5,
      padding:10,
      backgroundColor:colours.primaryBlack,
      borderRadius:10,
  },

  bannerGradient: {
      flex: 1, 
      //position: 'absolute', 
      //bottom: 0, 

      //alignSelf: 'center'
  },
  bannerImage: {
    height: width<1000?175:200,
    width: width<1000?175:200,
    resizeMode: 'cover',
    borderRadius:10,

},

  bannerText: {
      fontSize: width>1000? '.9rem': '.8rem',
      fontFamily:'muli',
      fontWeight: '800',
      color: colours.primaryGrey,
      marginTop: 20,
      //paddingStart:15

  },
  bannerTextSub: {
    fontSize: width>1000? '.7rem': '.6rem',
    fontFamily:'muli',
      fontWeight: '500',
      color: colours.primaryGrey,
      marginTop: 5,
      //paddingStart:15,
      marginBottom:10,
  },

  categoryContainer: {
    flexDirection: 'row',
    marginHorizontal: 10,
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomColor: colours.secondGrey,
    //borderBottomWidth: 1,
  },


  categoryTitle: {
    flex: 1,
    //justifyContent:'flex-start',
    justifyContent: 'center',
    marginVertical: 5,
    marginHorizontal: 10,


  },
  projectSeeAll: {
    justifyContent: 'flex-end',
    borderWidth: 0.5,
    paddingHorizontal: 15,
    paddingBottom: 10,
    borderRadius: 10,
  },

  categorySeeAll: {
    justifyContent: 'flex-end',
    borderWidth: 0.5,
    paddingHorizontal: 15,
    paddingBottom: 10,
    borderRadius: 10,
    backgroundColor: colours.brandColorExtraDark,
  },


  categoryTitleText: {
    fontSize: 20,
    color: colours.primaryGrey,
    marginTop: 2,
  },
  

  bannerArrow: {
    fontSize: width>1000? '1.2rem': '1rem',
    fontFamily:'muli',
    fontWeight: '600',
    color: colours.brandColor,
    textAlign:'center',
    justifyContent:'center',
    borderWidth:1.5,
    borderColor:colours.brandColorDark,
    backgroundColor:'rgba(0,0,0,0.2)',
    borderRadius:5,
    //marginTop: 10,
    marginLeft:15,
    paddingStart:12,
    paddingEnd:9,
    paddingVertical:5,
  },
  
  yearBoxContainer:
  {
    //marginHorizontal: 10,
    marginVertical: 20,
    paddingVertical: 20,
  },
  yearBoxRow: {
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 20,

  },
  yearBox: {
    flex: 2,
    //width: (widthNew / 2.5),
    width: 300,

    height: 200,
    borderRadius: 15,
    justifyContent: 'center',
    marginHorizontal: 15,
    marginVertical: 5,
    backgroundColor: '#9c27b0',
  },
  yearBox2: {
    flex: 2,
    width: 200,
    height: 170,
    borderRadius: 15,
    justifyContent: 'center',
    marginHorizontal: 15,
    marginVertical: 5,
    backgroundColor: '#ad1457'
  },
  yearBox3: {
    flex: 2,
    width: 200,
    height: 170,
    borderRadius: 15,
    justifyContent: 'center',
    marginHorizontal: 15,
    marginVertical: 5,
    backgroundColor: '#c63f17'
  },
  yearBox4: {
    flex: 2,
    width: 200,
    height: 170,
    borderRadius: 15,
    justifyContent: 'center',
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: '#0077c2'
  },
  yearBoxText: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center',
    fontWeight: '600'

  },
  yearBoxTextHeader: {
    marginVertical:0,
    fontSize: '2rem',
    paddingLeft:5,
    color: colours.brandColor,
    fontWeight: '700',
    fontFamily:'muli'

  },
  featuredTodayText: {
    marginTop:50,
    marginBottom:10,
    fontSize: '2rem',
    paddingLeft:5,
    color: colours.brandColor,
   // textAlign: 'center',
    fontWeight: '700',
    fontFamily:'muli'
  },



  listImageSpecial: {
    width: 250,
    height: 250,
    borderRadius: 20,
    borderWidth: 10,
    borderColor: colours.firstGrey,
    //marginVertical:5,
  },

  videoBoxRow: {
    margin: 5,
    flexDirection: 'row',
    justifyContent: 'center',

  },
  videoBox: {
    flex: 1,
    width: '75%',
    paddingVertical: 15,
    borderRadius: 15,
    justifyContent: 'center',
    backgroundColor: colours.primaryBlack,
    borderColor: colours.highlightBlack,
    borderWidth: 2,
  },
  videoBoxText: {
    fontSize: 17,
    paddingVertical:8,
    color: colours.forthGrey,
    textAlign: 'center',
    fontWeight: '600',
    borderBottomColor:colours.firstGrey,
    borderBottomWidth:.5,
  },



  footer: {
    fontSize: 20,
    color: colours.primaryGrey,
    marginVertical: 30,

  },

});
  

export default ServicesListPage;
  
  


