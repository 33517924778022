

import React,{useState}from "react";
import { Image,ImageBackground,View, Text, Dimensions, StyleSheet,TouchableOpacity,Linking,ActivityIndicator } from "react-native";
import colours from '../config/colours'
const { height, width } = Dimensions.get('window')

const ProjectImage = ({
    navigation, imgURL, toggleModal
}) => {
  const [imageLoading,setImageLoading]=useState(true)


return(
    <View style={styles.modalContainer}>
       
       

       {imageLoading && 
        <View style={styles.imageLoader}>
        <ActivityIndicator size="large" color={colours.brandColorLight} />
        </View>
        }

         <Image 
          style={styles.fullsizeimage}
          source={{uri:imgURL}}
          onLoadEnd={(e) => setImageLoading(false) }
        />         


                

                <View 
                style={styles.closeButtonContainer}
                >
                    <TouchableOpacity
                    onPress={toggleModal} 
                    >
                        <View style={styles.closeContainer}>
                            <Text style={styles.closeText}>X</Text>
                        </View>

                    </TouchableOpacity>
                </View>

            </View>

)};

export default ProjectImage;

const styles = StyleSheet.create({

  modalContainer:{
    flex:1,
    backgroundColor:colours.primaryBlack,      
    alignItems:'center',
    justifyContent:'center',
    alignSelf:'center',
    width:width>1000? 1280:width,
    margin:width>height?0:-15,
    borderRadius:25,
  },

imageLoader: {
  flex: 1, 
  position: 'absolute', 
  alignSelf: 'center',
  justifyContent:'center'
},
  fullsizeimage:{
      width:width>1000? 1080:width,
      height:width>height?height-height*.2:height-height*.1,
      resizeMode:'contain',
      alignSelf:'center',
      overflow: 'hidden',
      borderRadius:25
  },  
  
      closeContainer: {
        backgroundColor: colours.brandColorDark,
        alignItems:'center',
        borderRadius:20,
        paddingHorizontal:20,
        paddingVertical:5,
      },
      closeButtonContainer: {
        flex:1,
        alignItems:'center',
        color:'red',
        marginVertical:10,
      },
      closeText: {
        fontSize: 30,
        color: colours.primaryWhite,
      },

    
        

});


