import React, { createContext, useState,useEffect } from 'react';
import { Alert,Platform,Dimensions } from "react-native";
import { CommonActions } from '@react-navigation/native';
import { firebase } from '../config/firebase'
import { useNavigation } from '@react-navigation/native';
const { height, width } = Dimensions.get('window')


export const AuthContext = createContext({});

export const DataProvider = ({ children }) => {
   

    useEffect(() => {
        getAllProjects();
        firebase.analytics();
  // eslint-disable-next-line
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [featuredData, setFeaturedData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [serviceAllData, setServiceAllData] = useState([]);
  const [categoryAllData, setCategoryAllData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [allProjectsData, setAllProjectsData] = useState([]);


  const postsRef = firebase.firestore().collection('projects');
  const projectsRef = firebase.firestore().collection('homedata');

  const getAllProjects = async () => {

    setIsLoading(true);
    const snapshot = await projectsRef
    .where('docname','==','homedata')
    .get();
  
    if (!snapshot.empty) {
      let Projects = [];
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      for (let i = 0; i < snapshot.docs.length; i++) {
        Projects.push(snapshot.docs[i].data());
      }
  
      setAllProjectsData(Projects); 
  
      const projectsdata = Object.keys(Projects).reduce((result, key) => {return result.concat(Projects[key].Projects)},[])
  
      const servicesdata = Object.keys(projectsdata).reduce((result, key) => {return result.concat(projectsdata[key].services)},[])
      const uniqueservices = [...new Set(Object.values(servicesdata))];
  
      const categoriesdata = Object.keys(projectsdata).reduce((result, key) => {return result.concat(projectsdata[key].category)},[])
      const uniquecategories = [...new Set(Object.values(categoriesdata))];
  
  
      //Category
      let ListingCategories = uniquecategories.map(
        elem => ({
          category: elem,    
          projects : projectsdata.filter(item=>item.category.includes(elem)),
          projectsno : projectsdata.filter(item=>item.category.includes(elem)).length,
          projectsid : (projectsdata.filter(item=>item.category.includes(elem))).map(id=>(id.id)),
        })
      )
  
  
  let ListingCategoriesSorted = ListingCategories.sort((a, b) => parseFloat(a.projectsno) - parseFloat(b.projectsno));
  
  let CategoriesListProjectIds = ListingCategoriesSorted.map(
    data =>({
             categoryname: {categoryname:data.category},
             projects: (data.projects).map((a) => 
                    ({sort: Math.random(), value: a}))
                    .sort((a, b) => a.sort - b.sort)
                    .map((a) => a.value)
      })
  )
  
  let CategoriesListProjectIdsSingle = CategoriesListProjectIds.map(
    (data,i,arr) =>
    {  
      if (arr[i-1]==undefined) { 
        return  ({project:data.projects[0], categoryname:data.categoryname});
      } else {
             const newArr= data.projects.filter(e=> e.id!== arr[i-1].projects[0].id)
  
              return ({project: newArr[0] ,categoryname:data.categoryname })
  
      }
            }
                 
    
  )
  
  let ListingCategoriesShuffle = CategoriesListProjectIdsSingle
  .map((a) => ({sort: Math.random(), value: a}))
  .sort((a, b) => a.sort - b.sort)
  .map((a) => a.value)
  
  setCategoriesData(ListingCategoriesShuffle)
  
  //Services
  
  let Listing = uniqueservices.map(
    elem => ({
      service: elem,    
      projects : projectsdata.filter(item=>item.services.includes(elem)),
      projectsno : projectsdata.filter(item=>item.services.includes(elem)).length,
      projectsid : (projectsdata.filter(item=>item.services.includes(elem))).map(id=>(id.id)),
    })
  )
  
  
  let ListingSorted = Listing.sort((a, b) => parseFloat(a.projectsno) - parseFloat(b.projectsno));
  
  let ServicesListProjectIds = ListingSorted.map(
    data =>({
             categoryname: {categoryname:data.service},
             projects: (data.projects).map((a) => 
                    ({sort: Math.random(), value: a}))
                    .sort((a, b) => a.sort - b.sort)
                    .map((a) => a.value)
      })
  )
  
  let ServicesListProjectIdsSingle = ServicesListProjectIds.map(
    (data,i,arr) =>
    {  
      if (arr[i-1]==undefined) { 
        return  ({project:data.projects[0], categoryname:data.categoryname});
      } else {
             const newArr= data.projects.filter(e=> e.id!== arr[i-1].projects[0].id)
  
              return ({project: newArr[0] ,categoryname:data.categoryname })
  
      }
            }
                 
    
  )
  
  let ListingShuffle = ServicesListProjectIdsSingle
  .map((a) => ({sort: Math.random(), value: a}))
  .sort((a, b) => a.sort - b.sort)
  .map((a) => a.value)
  
  var ListingShuffleNew = ListingShuffle.reduce((obj, item) => (obj[item.project] = item.project, obj) ,{});
  
  
  
  setBannerData(ListingShuffle)
  setServiceAllData(ServicesListProjectIds)
  setCategoryAllData(CategoriesListProjectIds)
  
      //Featured Section
       const featuredDataShuffle = projectsdata.map((a) => ({sort: Math.random(), value: a})).sort((a, b) => a.sort - b.sort).map((a) => a.value)
            const featuredData = width>1000?featuredDataShuffle.splice(featuredDataShuffle.length-5):featuredDataShuffle.splice(featuredDataShuffle.length-3)
     setFeaturedData(featuredData)
  
  
    } else {
      setLastDoc(null);
    }
    setIsLoading(false);
  }


  return (
    <AuthContext.Provider
      value={{
        bannerData,
        featuredData,
        serviceAllData,
        categoriesData,
        categoryAllData,
        allProjectsData,
        
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };