import React from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView } from 'react-native';
import Modal from 'react-native-modal';

import { firebase } from '../config/firebase'
import 'firebase/analytics'

import colours from '../config/colours';
import Header from '../components/header'
import Banner from '../components/banner'
import Featured from '../components/featured'

import Footer from '../components/footer'
import Services from '../components/services';
import Projects from '../components/projects';



const { height, width } = Dimensions.get('window')


const HomeScreen = ({navigation}) => {
    

    return (
      <SafeAreaView>
        <ScrollView>
      <View style={styles.safeView}>

 <Header />
<Banner />

          <Text numberOfLines={1} style={styles.featuredTodayText} >
                                Featured Today
          </Text>
<Featured />


              <View style={styles.categoryContainer}>
                <View style={styles.categoryTitle}>
                <Text numberOfLines={1} style={styles.featuredTodayText} >
                      Projects
                </Text>
                </View>

                <View style={styles.projectSeeAll} >
                <TouchableOpacity 
                  onPress={() => navigation.navigate('Projects')} 


                    >
                <Text style={styles.bannerArrow}>All Projects{' '}{'\u276F'}</Text>

                </TouchableOpacity>
                </View>

                

              </View>
              

                <View style={styles.videoBoxRow}>

<Projects />

                </View>







                <View style={styles.categoryContainer}>
                <View style={styles.categoryTitle}>
                <Text numberOfLines={1} style={styles.featuredTodayText} >
                      What We Do
                </Text>
                </View>

                <View style={styles.projectSeeAll} >
                <TouchableOpacity 
                  onPress={() => navigation.navigate('Services List')} 


                    >
                <Text style={styles.bannerArrow}>Learn More{' '}{'\u276F'}</Text>

                </TouchableOpacity>
                </View>

                

              </View>
<Services />




<Footer />



      </View>
      </ScrollView>
      </SafeAreaView>

    );
  };



const styles = StyleSheet.create({
  safeView: {
    flex: 1,    
    maxWidth:width>1000?1000:width,
    minWidth:width>1000? 900:width,
    alignSelf:'center'
  },


  categoryContainer: {
    flexDirection: 'row',
    marginHorizontal: 10,
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomColor: colours.secondGrey,
  },


  categoryTitle: {
    flex: 1,
    //justifyContent:'flex-start',
    justifyContent: 'center',
    marginVertical: 5,

  },
  projectSeeAll: {
    justifyContent: 'flex-end',
    borderWidth: 0.5,
    paddingHorizontal: 15,
    paddingBottom: 10,
    borderRadius: 10,
  },

  bannerArrow: {
    fontSize: width>1000? '1.2rem': '1rem',
    fontFamily:'muli',
    fontWeight: '600',
    color: colours.brandColor,
    textAlign:'center',
    justifyContent:'center',
    borderWidth:1.5,
    borderColor:colours.brandColorDark,
    backgroundColor:'rgba(0,0,0,0.2)',
    borderRadius:5,
    //marginTop: 10,
    marginLeft:15,
    paddingStart:12,
    paddingEnd:9,
    paddingVertical:5,
  },
  
  featuredTodayText: {
    marginTop:50,
    marginBottom:10,
    fontSize: '2rem',
    paddingLeft:5,
    color: colours.brandColor,
   // textAlign: 'center',
    fontWeight: '700',
    fontFamily:'muli'
  },



  videoBoxRow: {
    margin: 5,
    flexDirection: 'row',
    justifyContent: 'center',

  },


});
  

export default HomeScreen;
  
  


