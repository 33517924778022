
import 'react-native-gesture-handler';
import React from 'react';
import { Platform,StyleSheet,View} from 'react-native';


import { NavigationContainer, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';


import HomeScreen from './homeScreen' 

import ContactScreen from './contactScreen'
import AboutScreen from './aboutScreen';
import ProjectsList from '../components/projectsList';
import ProjectDetailsPage from '../components/projectDetailsPage';
import ServicesListPage from './servicesListPage';
import ServiceDetails from './serviceDetails';
import ProjectsPage from './projectsPage';



const HomeStack = createStackNavigator();
function HomeStackScreens({route, navigation}) {


  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false}}>
      <HomeStack.Screen name='HOME' component={HomeScreen} options={{headerShown: false}}/>
      <HomeStack.Screen name='Projects' component={ProjectsPage} options={{headerTitleAlign:'center', title: 'Project',}}/>
      <HomeStack.Screen name='Projects List' component={ProjectsList} options={{headerTitleAlign:'center', title: 'Projects',}}/>
      <HomeStack.Screen name='Project Details' component={ProjectDetailsPage} options={{headerTitleAlign:'center', title: 'Project',}}/>
      <HomeStack.Screen name='Services List' component={ServicesListPage} options={{headerTitleAlign:'center', title: 'Services',}}/>
      <HomeStack.Screen name='Service Details' component={ServiceDetails} options={{headerTitleAlign:'center', title: 'Service',}}/>
      <HomeStack.Screen name='ABOUT US' component={AboutScreen} options={{headerTitleAlign:'center', title: 'About Us',}}/>
      <HomeStack.Screen name='CONTACT' component={ContactScreen} Options={{ headerBackTitle: 'BACK', headerTitle: null}} />
    </HomeStack.Navigator>
  );
}

export default HomeStackScreens;
