import React, {useEffect,useState,useRef } from 'react';
import { FlatList,ScrollView,StyleSheet,View, Image, Text, TouchableOpacity, Dimensions,SafeAreaView,Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import colours from '../config/colours';
import "typeface-muli";

const { height, width } = Dimensions.get('window')

const Services = ({page}) => {
  const navigation = useNavigation();
   
    return (
      <SafeAreaView  style={styles.pagestyle}>
        <ScrollView 
        style={styles.headerItem}> 

              
                <View style={styles.container}>

                <TouchableOpacity 
                          onPress={() => navigation.navigate('Service Details', {servicepage:'Interiors Design Consultancy'})} 
                        >
                  <View style={styles.textRow}>
                  <Text style={styles.containerText}>Interiors Design Consultancy
                  <Text style={styles.bannerArrow}>{'\u276F'}</Text>
                  </Text>
                  </View>
                  </TouchableOpacity>

                  <TouchableOpacity 
                          onPress={() => navigation.navigate('Service Details', {servicepage:'Space Planning & 3D Visualizations'})} 
                          >
                  <View style={styles.textRow}>
                  <Text style={styles.containerText}>Space Planning & 3D Visualizations
                  <Text style={styles.bannerArrow}>{'\u276F'}</Text>
                  </Text>
                  </View>
                  </TouchableOpacity>


                  <TouchableOpacity 
                          onPress={() => navigation.navigate('Service Details', {servicepage:'PM Planning Turnkey Projects'})} 
                          >
                  <View style={styles.textRow}>
                  <Text style={styles.containerText}>PM Planning Turnkey Projects
                  <Text style={styles.bannerArrow}>{'\u276F'}</Text>
                  </Text>
                  </View>
                  </TouchableOpacity>

                  <TouchableOpacity 
                          onPress={() => navigation.navigate('Service Details', {servicepage:'Complete Fit-Out'})} 
                          >
                  <View style={styles.textRow}>
                  <Text style={styles.containerText}>Complete Fit-Out
                  <Text style={styles.bannerArrow}>{'\u276F'}</Text>
                  </Text>
                  </View>
                  </TouchableOpacity>

                  <TouchableOpacity 
                          onPress={() => navigation.navigate('Service Details', {servicepage:'Landscaping Design and Outdoor Furnishings'})} 
                          >
                  <View style={styles.textRow}>
                  <Text style={styles.containerText}>Landscaping Design and Outdoor Furnishings
                  <Text style={styles.bannerArrow}>{'\u276F'}</Text>
                  </Text>
                  </View>
                  </TouchableOpacity>

                  <TouchableOpacity 
                          onPress={() => navigation.navigate('Service Details', {servicepage:'Exhibition Stand Design & Build Services'})} 
                          >
                  <View style={styles.textRow}>
                  <Text style={styles.containerText}>Exhibition Stand Design & Build Services
                  <Text style={styles.bannerArrow}>{'\u276F'}</Text>
                  </Text>
                  </View>
                  </TouchableOpacity>

                  <TouchableOpacity 
                          onPress={() => navigation.navigate('Service Details', {servicepage:'Snagging'})} 
                          >
                  <View style={styles.textRow}>
                  <Text style={styles.containerText}>Property Snagging and Construction Auditing Services (MAG)
                  <Text style={styles.bannerArrow}>{'\u276F'}</Text>
                  </Text>
                  </View>
                  </TouchableOpacity>

                  <TouchableOpacity 
                          onPress={() => navigation.navigate('Service Details', {servicepage:'Virtual Reality'})} 
                          >
                  <View style={styles.textRow}>
                  <Text style={styles.containerText}>Virtual Reality
                  <Text style={styles.bannerArrow}>{'\u276F'}</Text>
                  </Text>
                  </View>
                  </TouchableOpacity>

                </View>


        </ScrollView>
      </SafeAreaView>

    );
  };


  export default Services;


const styles = StyleSheet.create({
    pagestyle: {
        flex: 1,
    },
    headerItem: {
        flex: 1,
        maxWidth: 1000,
        marginTop:10,
    },

container: {
  flex: 1,
  //width: '75%',
  paddingVertical: 15,
  borderRadius: 15,
  justifyContent: 'center',
  marginBottom:50,
  //backgroundColor: colours.primaryBlack,

},
textRow: {
  flex: 1,
  //width: '75%',
  paddingVertical: 15,
  marginHorizontal:5,
  marginBottom:3,
  borderRadius: 15,
  //justifyContent: 'center',
  backgroundColor: colours.primaryBlack,
  //borderBottomColor:colours.firstGrey,
  //borderBottomWidth:.5,

},

containerText: {
  fontSize: width>1000?'1.3rem':'1rem',
  fontFamily:'muli',
  paddingVertical:8,
  paddingHorizontal:5,
  lineHeight:30,
  color: colours.forthGrey,
  textAlign: 'center',
  fontWeight: '600',

},
bannerArrow: {
  fontSize: width>1000? '1.1rem': '.9rem',
  fontFamily:'muli',
  fontWeight: '400',
  color: colours.brandColorExtraDark,
  textAlign:'center',
  justifyContent:'center',
  borderWidth:.7,
  borderColor:colours.brandColorExtraDark,
  borderRadius:5,
  //marginTop: 10,
  marginLeft:15,
  paddingStart:15,
  paddingEnd:12,
  paddingVertical:5,
},
categoryTitle: {
  flex: 1,
  //justifyContent:'flex-start',
  justifyContent: 'center',
  marginVertical: 5,

},
featuredTodayText: {
  marginTop:50,
  marginBottom:10,
  fontSize: '2rem',
  paddingLeft:5,
  color: colours.brandColor,
 // textAlign: 'center',
  fontWeight: '700',
  fontFamily:'muli'
}

});
  

  
  


