import React from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView } from 'react-native';
import Modal from 'react-native-modal';

import { firebase } from '../config/firebase'
import 'firebase/analytics'

import colours from '../config/colours';
import Header from '../components/header'
import Banner from '../components/banner'
import Featured from '../components/featured'

import Footer from '../components/footer'
import Services from '../components/services';
import Projects from '../components/projects';



const { height, width } = Dimensions.get('window')


const HomeScreen = ({navigation}) => {
    

    return (
      <SafeAreaView>
        <ScrollView>
      <View style={styles.safeView}>

 <Header />



 <View style={styles.collectionsContainerVideo}>
              <View style={styles.categoryContainer}>
                <View style={styles.categoryTitle}>
                    <Text numberOfLines={1} style={styles.categoryTitleText} >
                     About PDC Interiors    
                        

                    </Text>
                </View>
            
              </View>
              
              <View style={styles.videoBoxCenter}>

                <View style={styles.videoBoxRow}>
               
                <View style={styles.videoBox}>
                  <Text style={styles.videoBoxText}>About Us</Text>
                  <Text style={styles.DescriptionText}>
                  A member of the fast growing PDC Group, PDC interiors is specialized in providing high end interior design and fit out services. PDC INTERIORS is based in UAE and has served clients not only locally and in the Middle East, but also internationally. We are a dynamic talented design & Contracting team & we will provide full service-from conceptualization to completion, Our full turnkey solutions extends till furniture, paintings and full accessories supply. Our exceptionally creative designs and ideas will ensure the best and maximum utilization of the space within a reasonable and cost effective budgets.{"\n"}{"\n"}

                    AT PDC INTERIORS we work closely with our clients who can confidently count on us on delivering a complete design and project management package to assure our clients of complete and integrated project with a highly personalized level of service and attention to detail.{"\n"}{"\n"}

                    We specialize in building custom-made stands and tailor each stand to our client’s individual specifications and exhibiting needs. Our team of experts who has extensive knowledge and experience in the field, we’ll make sure that your Ideas will turn into Reality. Our Interior-Fit-out division, manages Fit-outs, Joinery and Woodworks, MEP Works, Engineering and Electro-mechanical Activities.{"\n"}{"\n"}

                    Our Experienced and certified project managers remain dedicated to the project from initiation till handover to ensure we meet the promised deadline and deliver the projects up to the satisfaction of our clients and exceed . Our sectors covers residential, commercial, retail, healthcare and hospitality projects with a proven record of achievements and repeated clients. At PDC INTERIORS , WE DESIGN TO INSPIRE
                  </Text>
                </View>
               </View>


              <View style={styles.videoBoxRow}>
               
                <View style={styles.videoBox}>
                  <Text style={styles.videoBoxText}>Vision</Text>
                  <Text style={styles.DescriptionText}>
                    
                  To combine academic background and professional experience with 
                  sensitivity to our customers’ ideas, needs and preferences, 
                  to achieve their satisfaction.
  
                    </Text>
                </View>
               </View>


              <View style={styles.videoBoxRow}>
               
                <View style={styles.videoBox}>
                  <Text style={styles.videoBoxText}>Mission</Text>
                  <Text style={styles.DescriptionText}>
                    
                  To maintain strong client relationships by focusing 
                  on personalizing spaces for clients based on their specific 
                  requirements, which we custom design with new and innovative 
                  designs and with the highest level of professional expectation.

                  </Text>
                </View>
               </View>

              
              <View style={styles.videoBoxRow}>
               
                <View style={styles.videoBox}>
                  <Text style={styles.videoBoxText}>Founder</Text>
                  <Text style={styles.DescriptionText}>
                  Marwa Abdelaziz is a pioneer and expert in developing projects 
                  to build revenue, profits and corporate visibility with 20 years of 
                  extensive experience in all facets of projects starting with site identification, 
                  developing and overseeing the implementation of the feasibility plans for projects; 
                  actively participating in the various financial analysis, acquisition, 
                  architectural and interior design review and tendering.{"\n"}{"\n"}

                  She is in the interior decoration industry since 2008, during which she has completed an impressive portfolio of residential, commercial and hospitality projects from conceptual phase till full turnkey handover. Held Senior positions in reputable firms like Al Rostamani and Noor Islamic Bank where she was working closely with top notch experts in the field.{"\n"}{"\n"}

                  Marwa holds an Architectural Engineering degree, PMP and Master in Project Management from the American Academy U.S.A.{"\n"}{"\n"}

                  To lear more please visit : <TouchableOpacity
                onPress={() => navigation.navigate('PROJECTS')} 
                >
                    <Text numberOfLines={1} style={styles.categorySeeAllText} >
                    www.MarwaAbdelaziz.com
                    </Text>
                    </TouchableOpacity>
                  </Text>
                  
                </View>

               </View>


               <View style={styles.videoBoxRow}>
               
               <View style={styles.videoBox}>
                 <Text style={styles.videoBoxText}>Team</Text>
                 <Text style={styles.DescriptionText}>
                 
                 At PDC Interiors , we rely on our people to maintain the success of our business.{"\n"}{"\n"}

We have a team of skilled and experienced designers who conceive ideas and translate them into stunning and feasible designs. Our designers work in close interaction with the clients to ensure that the client’s vision takes a beautiful shape and is within their budget.{"\n"}{"\n"}

Our experienced and certified Project Managers remain dedicated to the project from initiation till handover to ensure we meet the promised deadline and deliver the projects up to the satisfaction of our clients and exceed.{"\n"}{"\n"}

With PDC Interiors, you have a single point of contact who will take responsibility for the project’s scope of works and project timeline from its conception through to completion.

                 </Text>
               </View>
              </View>





            </View>
            </View>


<Footer />



      </View>
      </ScrollView>
      </SafeAreaView>

    );
  };



const styles = StyleSheet.create({
  safeView: {
    flex: 1,    
    maxWidth:width>1000?1000:width,
    minWidth:width>1000? 900:width,
    alignSelf:'center'
  },


  categoryContainer: {
    flexDirection: 'row',
    marginHorizontal: 15,
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomColor: colours.secondGrey,
    borderBottomWidth: 1,
  },

  categoryTitle: {
    flex: 4,
    //justifyContent:'flex-start',
    justifyContent: 'center',
    marginVertical: 5,

  },

  categorySeeAll: {
    justifyContent: 'flex-end',
    borderWidth: 0.5,
    paddingHorizontal: 15,
    paddingBottom: 10,
    borderRadius: 10,
    backgroundColor: colours.brandColorExtraDark,
  },

  categoryTitleText: {
    fontSize: 25,
    color: colours.primaryGrey,
    marginTop: 2,
    fontWeight:500
  },
  
  categorySeeAllText: {
    fontSize: 12,
    fontWeight: '600',
    color: colours.brandColorLight
  },



  collectionsContainerVideo: {
    marginHorizontal: 10,
    backgroundColor: colours.firstGrey,
    borderRadius: 20,
    paddingBottom: 5,
    marginVertical: 25,
    paddingVertical: 15,
  },


  videoBoxRow: {
    marginHorizontal: 5,
    marginVertical:15,
    //flexDirection: 'row',
    justifyContent: 'center',

  },
  videoBox: {
    flex: 1,
    //width: (widthNew) * .75,
    paddingVertical: 15,
    borderRadius: 15,
    justifyContent: 'center',
    backgroundColor: colours.primaryBlack,
    borderColor: colours.highlightBlack,
    borderWidth: 2,
  },
  videoBoxText: {
    fontSize: 20,
    color: colours.brandColorDark,
    textAlign: 'center',
    fontWeight: '600'
  },


  videoBoxCenter: {
    margin: 5,
    //flexDirection: 'row',
    alignItems:'center'

  },
  DescriptionText: {
    fontSize: 16,
    color: colours.forthGrey,
    textAlign: 'justify',
    fontWeight: '400',
    padding:18
  },


});
  

export default HomeScreen;
  
  


