import React, { useState, useEffect,useCallback } from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView } from 'react-native';
import Modal from 'react-native-modal';

import colours from '../config/colours';
import "typeface-muli";
import Header from './header'
import Footer from './footer'
import ProjectImage from './projectImage'

import { useNavigation } from '@react-navigation/native';

const { height, width } = Dimensions.get('window')

const ProjectDetailsPage = ({route}) => {
  const navigation = useNavigation();
  const [isModalVisibleContact, setModalVisibleContact] = useState(false);
  const [modalData, setModalData] = useState();

  const {pageData} = route.params
  const {id, title,location,city,country,client,description,services,category,images} = pageData
  
  let out = Object.keys(images).map(data => ({[data]: images[data]}))
  let thumbURL = Object.keys(images).filter(item=>item.startsWith('thumb')).map(data=>({[data]: images[data]}))
  let imgURL =Object.keys(images).filter(item=>item.startsWith('img')).map(data=>({[data]: images[data]}))


  const toggleModalContact = () => {
    setModalVisibleContact(!isModalVisibleContact);
  };

 
    
    const goHomeContact = () => {
      setModalVisibleContact(!isModalVisibleContact);
    };



  const renderList = ({item}) => {
    let thumbURL = Object.values(item)[0]
    let imgURLkey = Object.keys(item)[0]

    var imgURLnum = 'imgURL'+imgURLkey.slice(imgURLkey.length-2)
    var imgURL = images[imgURLnum]

    return (
      <TouchableOpacity 
      onPress={()=> {toggleModalContact();setModalData(imgURL);
        }}

>

                <View style={styles.itemFlatlist}>
                        <Image  
                              source={{ uri: thumbURL }} 
                              style={styles.bannerImage} 
                              />  
                  
      </View>

      </TouchableOpacity >

    )
  }




    return (
      <SafeAreaView>
        <ScrollView>
      <View style={styles.safeView}>

<Header />

<View style={styles.categoryTitle}>
                <Text style={styles.featuredTodayText} >
                      {title}
                </Text>
                </View>


                <View style={styles.bannerGradient}>
                <Text  style={styles.bannerText}>Services : 

                {
                  services.length ? services.map((itemTestArray) =>
                  (<span> {itemTestArray} {', '}</span>)) : '-'
                }
                </Text>

                  {location !== undefined && location.length>0 &&
                            <Text  style={styles.bannerText}>Location : {location}</Text>
                          }
                            <Text  style={styles.bannerText}>{city},{' '}{country}</Text>

                        </View>

                <FlatList 
                        //horizontal={true}
                        //scrollEnabled={false}
                        data={thumbURL}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={(item) => renderList(item)}
                        initialNumToRender={20}
                        numColumns={width>1000?4:2}
                        contentContainerStyle={styles.flatlistcontainer}
                        />
     

     <Modal 
isVisible={isModalVisibleContact} 
transparent={true}
onBackdropPress={goHomeContact}
animationIn={'zoomInDown'}
animationOut={'zoomOutDown'}

>
<View>


<ProjectImage 
imgURL={modalData}
toggleModal={toggleModalContact}
/>
</View>
</Modal>


<Footer />
      </View>
      </ScrollView>
      </SafeAreaView>

    );
  };



const styles = StyleSheet.create({
  safeView: {
    flex: 1,    
    maxWidth:width>1000?1000:width,
    minWidth:width>1000? 900:width,
    alignSelf:'center'
  },

  flatlistcontainer:
  {
      flex: 1,
      alignItems:'center',

  },

  itemFlatlist:
  {
      flex: 1,
      //height: width < 1001 ? 200 : 200,
      width:width < 1001 ? 200 : 220,
      alignItems:'center',
      marginTop:20,
      marginHorizontal:5,
      padding:10,
      backgroundColor:colours.primaryBlack,
      borderRadius:10,
  },

  bannerImage: {
    height: width<1000?175:200,
    width: width<1000?175:200,
    resizeMode: 'cover',
    borderRadius:10,

},
featuredTodayText: {
  marginTop:25,
  marginBottom:10,
  marginLeft:10,

  fontSize: width>1000?'2rem':'1.7rem',
  color: colours.brandColor,
 // textAlign: 'center',

  fontWeight: '700',
  fontFamily:'muli'
},

  bannerGradient: {
      flex: 1, 
      //position: 'absolute', 
      //bottom: 0, 

      //alignSelf: 'center'
  },


  bannerText: {
      fontSize: width>1000? '.9rem': '.8rem',
      fontFamily:'muli',
      fontWeight: '600',
      color: colours.primaryGrey,
      marginLeft: 15,
      //paddingStart:15

  },
  bannerTextSub: {
    fontSize: width>1000? '.7rem': '.6rem',
    fontFamily:'muli',
      fontWeight: '500',
      color: colours.primaryGrey,
      marginTop: 5,
      //paddingStart:15,
      marginBottom:10,
  },


  categoryTitle: {
    flex: 1,
    //justifyContent:'flex-start',
    justifyContent: 'center',
    marginVertical: 5,

  },




});
  

export default ProjectDetailsPage;
  
  


