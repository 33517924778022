import React, { useContext } from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView } from 'react-native';

import colours from '../config/colours';
import "typeface-muli";
import Header from '../components/header'
import Footer from '../components/footer'
import { useNavigation } from '@react-navigation/native';
import Services from '../components/services';
import ServiceProjects from '../components/serviceProjects';
import { AuthContext } from '../providers/dataProvider'

const { height, width } = Dimensions.get('window')

const ServiceDetails = ({route}) => {
  const navigation = useNavigation();

  const {serviceAllData } = useContext(AuthContext);

const {servicepage} = route.params

    return (
      <SafeAreaView>
        <ScrollView>
      <View style={styles.safeView}>

<Header />

<View style={styles.categoryContainer}>
                <View style={styles.categoryTitle}>
                <TouchableOpacity 
                  onPress={() => navigation.navigate('Services List', {page:true})} 
                    >

                  <Text numberOfLines={1} style={styles.featuredTodayText} >
                        Services
                  </Text>
                  </TouchableOpacity>
                </View>

                <View style={styles.projectSeeAll} >
                <TouchableOpacity 
                  //onPress={() => navigation.navigate('Services List', {page:true})} 
                  onPress={() => navigation.goBack()} 
                    >
                <Text style={styles.bannerArrow}>{'\u276E'}{' '}Go Back</Text>

                </TouchableOpacity>

                </View>

                

              </View>


{servicepage=='Interiors Design Consultancy' && (
  <View style={styles.serviceContainerRow}>
               
               <View style={styles.serviceContainer}>
                 <Text style={styles.serviceContainerText}>Interiors Design Consultancy</Text>
                 <Text style={styles.DescriptionText}>
                 We provide a total service encompassing all facets of the 
                 commercial & residential interiors process from design management 
                 through to completion of the built environment which take place 
                 in two phases as follows :{"\n"}{"\n"}
                 Programming The first phase of our projects is the programming phase. 
                 This phase is predesign and is critical to determine scope of work 
                 and identify the project team. Understanding the critical path of  
                 the project provides the basis of the project timeline. 
                 The client’s wishes for the project level of detail and finishes, 
                 function needs, furnishing style, budgetary requirements.{"\n"}{"\n"}
                 
                 Conception The first design phase is the concept phase. 
                 In many cases this phase may be broken into two distinct milestones; {"\n"}{"\n"}
                 concept and schematic design. {"\n"}
                 
                 Within the concept milestone; plan review, space planning, mood imagery, 
                 and initial material palette ideas are developed. {"\n"}{"\n"}
                 
                 Within the schematic milestone; interior sketches, elevations, ceiling designs, 
                 flooring patterns, furnishing mood and preliminary finish selections are 
                 developed for the key spaces.
                 </Text>


                 <ServiceProjects title={'Interior Design Consultancy'} />



                 <View style={styles.projectSeeAll} >
                <TouchableOpacity 
     onPress={() => navigation.navigate('Projects List', {title:'Interior Design Consultancy',category:'service'})} 
     >
                <Text style={styles.bannerArrow}>More Related Projects{' '}{'\u276F'}</Text>

                </TouchableOpacity>

                </View>



               </View>
              </View>
    )}



{servicepage=='Space Planning & 3D Visualizations' && (
 <View style={styles.serviceContainerRow}>
               
 <View style={styles.serviceContainer}>
 <Text style={styles.serviceContainerText}>Space Planning & 3D Visualizations</Text>
                  <Text style={styles.DescriptionText}>
                    
                    At PDC INTERIORS, we provide innovative solutions to help you make the most out of your space. 
                    Whether you wish to increase space efficiency , accommodate more stuff or simply create a 
                    more comfortable environment, we can devise a re-configuration plan to help optimize your place.{"\n"}{"\n"}
                    
                    For commercial projects we also help to implement alternative working systems, 
                    such as office zoning, hot-desking, touchdown areas and breakout zones, to ensure 
                    that your space is used to its maximum efficiency, whilst helping to promote 
                    interaction and communication to help make your business more efficient.{"\n"}{"\n"}
                    
                      We emphasis our ideas by creating high end 3D visuals and walk-throughs to 
                      help our clients visualize the ideas , feel the space and understand it easily 
                      using the latest technologies for modeling’s and renderings as well as final print out.
  
                    </Text>


   <ServiceProjects title={'Interior Design Consultancy'} />



   <View style={styles.projectSeeAll} >
  <TouchableOpacity 
         onPress={() => navigation.navigate('Projects List', {title:'Interior Design Consultancy',category:'service'})} 
 
      >
  <Text style={styles.bannerArrow}>More Related Projects{' '}{'\u276F'}</Text>

  </TouchableOpacity>

  </View>



 </View>
</View>
    )}

{servicepage=='PM Planning Turnkey Projects' && (
   <View style={styles.serviceContainerRow}>
               
   <View style={styles.serviceContainer}>
   <Text style={styles.serviceContainerText}>PM Planning Turnkey Projects</Text>
                  <Text style={styles.DescriptionText}>
                    
                  Project Management & Engineering
Our Project Managers and Project Engineers assist you as soon as there is activity on the construction site.
Our managers and engineers follow up on the quality, planning and budget of the works in a detailed way without losing sight of safety. Construction Site Inspectors, Team Leaders, Site Coordinators, Project Engineers and Project Managers together assure the timely delivery of a high quality construction or installation as described in the (technical) estimate.
Experience, knowledge & soft skills… {"\n"}{"\n"}
Our Project Managers and Engineers do not only excel in terms of their skills and in-depth knowledge of engineering, they all have well developed soft skills too.{"\n"}{"\n"}
At PDC INTERIORS we pay great attention to stimulating the flexibility, pro-activity, empathy, discipline, communication skills and people management skills of our experts since these traits are indispensable for good communication between the project team, the contractor, the authorities and other parties involved in the project. Our Project Managers and Engineers are often assisted on site or in the office by our experts in our Support Services department.
Our TEAM has extensive experience with monitoring orders, (resource) planning, budget and construction site progress with dedicated software such as SAP and primavera. {"\n"}{"\n"}
TURN-KEY PROJECTS{"\n"}
Do you lack the time and resources to manage and execute a particular project but wanted to deliver without compromising on quality and speed of execution? Our Turn-key Projects offer a flexible and scalable solution.
Our Turn-key Projects allow you to outsource your entire project to PDC INTERIORS . Our project managers and consultants are happy to take full responsibility for the planning, management and execution of your project, with or without the help of your experts.{"\n"}{"\n"}
HOW WE HANDLE TURN-KEY PROJECTS{"\n"}
After an initial kick-off meeting with a combined team of your and our experts,{"\n"}
{"\u2022 " }We describe the specifications of the deliverables and intermediate milestones{"\n"}
{"\u2022 " }We describe the planning, budget and risk management for the project{"\n"}
{"\u2022 " }We deliver, discuss and consolidate our project plan{"\n"}
{"\u2022 " }We assemble a team of experts to manage and execute the project{"\n"}
{"\u2022 " }We manage and execute the project, and we provide status updates as agreed{"\n"}
{"\u2022 " }We deliver the deliverables according to plan{"\n"}
{"\u2022 " }We discuss the project and the lessons learned in a post-project meeting{"\n"}{"\n"}
THE BENEFITS OF TURN-KEY PROJECTS{"\n"}
We take full responsibility for the planning, management and execution of your project.{"\n"}{"\n"}
You will experience the following benefits:{"\n"}
The diversity and size of our workforce enable us to:{"\n"}
Start today; we can assemble project teams at a very short notice{"\n"}
Capitalize on the individual strengths of our experts to optimize quality and execution time{"\n"}
Work on a very tight time schedule; we do have backup experts for every project.{"\n"}{"\n"}
Our project managers and experts think and act proactively to optimize quality and execution time. 
We take responsibility but you will always be in control.

                  </Text>


     <ServiceProjects title={'PM Planning Turnkey Projects'} />



     <View style={styles.projectSeeAll} >
    <TouchableOpacity 
               onPress={() => navigation.navigate('Projects List', {title:'PM Planning Turnkey Projects',category:'service'})} 

        >
    <Text style={styles.bannerArrow}>More Related Projects{' '}{'\u276F'}</Text>

    </TouchableOpacity>

    </View>



   </View>
  </View>
    )}

{servicepage=='Complete Fit-Out' && (
   <View style={styles.serviceContainerRow}>
               
   <View style={styles.serviceContainer}>
   <Text style={styles.serviceContainerText}>Complete Fit-Out</Text>
                  <Text style={styles.DescriptionText}>
                    
                    We offer competitive turnkey fit-out contracting services to provide our clients a truly one stop shop experience.{"\n"}{"\n"}
  PDC INTERIORS portfolios include prestigious fit-out projects in the region including Luxury residential villas & palaces , Leisure industry, Corporate offices, Banks and Retail projects.{"\n"}{"\n"}
  One of our greatest assets is our ability and flexibility to provide a single service or combination of design / construction management / contracting services.
  
                    </Text>


     <ServiceProjects title={'PM Planning Turnkey Projects'} />



     <View style={styles.projectSeeAll} >
    <TouchableOpacity 
                     onPress={() => navigation.navigate('Projects List', {title:'PM Planning Turnkey Projects',category:'service'})} 

        >
    <Text style={styles.bannerArrow}>More Related Projects{' '}{'\u276F'}</Text>

    </TouchableOpacity>

    </View>



   </View>
  </View>
    )}

{servicepage=='Landscaping Design and Outdoor Furnishings' && (
   <View style={styles.serviceContainerRow}>
               
   <View style={styles.serviceContainer}>
   <Text style={styles.serviceContainerText}>Landscaping Design and Outdoor Furnishings</Text>
                  <Text style={styles.DescriptionText}>
                  PDC INTERIORS provides landscape design, site planning, and master planning services for our esteemed clients.{"\n"}{"\n"}
                    Our talented team provide a diverse range of landscape architectural 
                    services to real estate developers, institutional facilities, business and industry.{"\n"}{"\n"}

                    We have a broad range of site design experience for all aspects of land development and regularly assist our clients. 
                    Our landscape architecture practice focuses on the following:{"\n"}{"\n"}

                    Feasibility Studies{"\n"}
                    {"\u2022 " }Commercial and Retail Developments{"\n"}
                    {"\u2022 " }Industrial and Office Park Developments{"\n"}
                    {"\u2022 " }School and Institutional Developments{"\n"}
                    {"\u2022 " }Recreational Facilities{"\n"}
                    {"\u2022 " }Residential Developments{"\n"}
                    {"\u2022 " }Landscape and Irrigation Design{"\n"}{"\n"}
                    PDC INTERIOR’S landscape architecture services range from the 
                    decision-making process of building location selection depending on the grading, 
                    storm-water plans and other construction aspects, to the attractiveness and utilization of 
                    the environmental features, to add value to the development.
                  </Text>


                  <ServiceProjects title={'PM Planning Turnkey Projects'} />



     <View style={styles.projectSeeAll} >
    <TouchableOpacity 
                     onPress={() => navigation.navigate('Projects List', {title:'PM Planning Turnkey Projects',category:'service'})} 
 
        >
    <Text style={styles.bannerArrow}>More Related Projects{' '}{'\u276F'}</Text>

    </TouchableOpacity>

    </View>



   </View>
  </View>
    )}

{servicepage=='Exhibition Stand Design & Build Services' && (
   <View style={styles.serviceContainerRow}>
               
   <View style={styles.serviceContainer}>
   <Text style={styles.serviceContainerText}>Exhibition Stand Design & Build Services</Text>
                 <Text style={styles.DescriptionText}>
                 Worried about what it will cost to exhibit?{"\n"}{"\n"}
We provide competitive, affordable exhibition stands In Dubai.{"\n"}{"\n"}
PDC INTERIORS PROFESSIONAL EXPERTISE – EXHIBITION STANDS, TRADE SHOW DISPLAYS, EXHIBITION BOOTHS!{"\n"}{"\n"}
As one of the most experienced and established exhibition stand designers & trade show booth designers in Dubai, we would love it if you give us the opportunity to work with you in your exhibition booth, trade show booths or exhibit display project.
                 </Text>


     <ServiceProjects title={'Design&Build Services'} />



     <View style={styles.projectSeeAll} >
    <TouchableOpacity 
     onPress={() => navigation.navigate('Projects List', {title:'Exhibition Stand',category:'service'})} 
        >
    <Text style={styles.bannerArrow}>More Related Projects{' '}{'\u276F'}</Text>

    </TouchableOpacity>

    </View>



   </View>
  </View>
    )}

{servicepage=='Snagging' && (
   <View style={styles.serviceContainerRow}>
               
   <View style={styles.serviceContainer}>
   <Text style={styles.serviceContainerText}>Property Snagging and Construction Auditing Services{"\n"} (MAG) </Text>
                  <Text style={styles.DescriptionText}>
                  MAG by PDC INTERIORS is something you should not miss{"\n"}
Explore it yourself today because it will give the success you want tomorrow.{"\n"}{"\n"}
PDC INTERIORS Snagging and construction Auditing services will spot Even minor faults that can lead to more serious issues in the future because process of fixing such issues can be both expensive and time-consuming.{"\n"}{"\n"}
@PDC INTERIORS we are working round the clock to serve our clients in the best way and exceed their expectations.                   
               </Text>


     <ServiceProjects title={'Snagging'} />



     <View style={styles.projectSeeAll} >
    <TouchableOpacity 
     onPress={() => navigation.navigate('Projects List', {title:'Snagging',category:'service'})} 


        >
    <Text style={styles.bannerArrow}>More Related Projects{' '}{'\u276F'}</Text>

    </TouchableOpacity>

    </View>



   </View>
  </View>
    )}

{servicepage=='Virtual Reality' && (
   <View style={styles.serviceContainerRow}>
               
   <View style={styles.serviceContainer}>
   <Text style={styles.serviceContainerText}>Virtual Reality</Text>
                 <Text style={styles.DescriptionText}>
                 PDC Interiors has an immersive experience technological production team.{"\n"}{"\n"}
                 Our passion and our expertise is in creative 360 Virutal Reality Visualizations  that captivate viewers in entirely new ways.{"\n"}{"\n"}


                  With over 10 years experience creating 360º and VR, 
                  we offer a full roster of immersive and experiential services including VR (Virtual Reality 360) visualization and capture  consultation.
                   </Text>


     <ServiceProjects title={'Virtual Reality'} />



     <View style={styles.projectSeeAll} >
    <TouchableOpacity 
       onPress={() => navigation.navigate('Projects List', {title:'Virtual Reality',category:'service'})} 

 
        >
    <Text style={styles.bannerArrow}>More Related Projects{' '}{'\u276F'}</Text>

    </TouchableOpacity>

    </View>



   </View>
  </View>
    )}







<Footer />
      </View>
      </ScrollView>
      </SafeAreaView>

    );
  };


  export default ServiceDetails;

const styles = StyleSheet.create({
  safeView: {
    flex: 1,    
    maxWidth:width>1000?1000:width,
    minWidth:width>1000? 900:width,
    alignSelf:'center'
  },

  categoryTitle: {
    flex: 1,
    //justifyContent:'flex-start',
    justifyContent: 'center',
    
    marginVertical: 5,

  },
  featuredTodayText: {
    marginBottom:10,
    fontSize: '2rem',
    paddingLeft:5,
    color: colours.brandColor,
   // textAlign: 'center',
    fontWeight: '700',
    fontFamily:'muli'
  },


  serviceContainerRow: {
    marginHorizontal: 5,
    marginVertical:15,
    //flexDirection: 'row',
    justifyContent: 'center',

  },
  serviceContainer: {
    flex: 1,
    //width: (widthNew) * .75,
    paddingVertical: 15,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems:'center',
    backgroundColor: colours.primaryBlack,
    borderColor: colours.highlightBlack,
    borderWidth: 2,
  },
  serviceContainerText: {
    fontSize: 20,
    color: colours.brandColorDark,
    textAlign: 'center',
    fontWeight: '600',
    fontFamily:'muli',

  },

  DescriptionText: {
    fontSize: width>1000? '1.2rem':'1rem',
    color: colours.forthGrey,
    fontFamily:'muli',
    textAlign: 'justify',
    fontWeight: '400',
    paddingHorizontal:width>1000? 30:15,
    paddingVertical:width>1000? 30:15
  },

  categoryContainer: {
    flexDirection: 'row',
    marginTop:50,

    marginHorizontal: 10,
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomColor: colours.secondGrey,
    //borderBottomWidth: 1,
  },

  bannerArrow: {
    fontSize: width>1000? '1.2rem': '1rem',
    fontFamily:'muli',
    fontWeight: '600',
    color: colours.brandColor,
    textAlign:'center',
    justifyContent:'center',
    borderWidth:1.5,
    borderColor:colours.brandColorDark,
    backgroundColor:'rgba(0,0,0,0.2)',
    borderRadius:5,
    //marginTop: 10,
    marginLeft:15,
    paddingStart:12,
    paddingEnd:9,
    paddingVertical:5,
  },
  projectSeeAll: {
    flexDirection:'row',
    justifyContent: 'flex-end',
    alignItems:'center',
    borderWidth: 0.5,
    paddingHorizontal: 15,
    paddingBottom: 10,
    borderRadius: 10,
  },

});
  

  
  


