import React, {useEffect,useState,useRef,useContext } from 'react';
import { FlatList,ScrollView,StyleSheet,View, Image, Text, TouchableOpacity, Dimensions,SafeAreaView,Linking ,ActivityIndicator} from 'react-native';
import { useNavigation } from '@react-navigation/native';

import colours from '../config/colours';
import "typeface-muli";
import { AuthContext } from '../providers/dataProvider'

const { height, width } = Dimensions.get('window')
let newColor = true

const Banner = () => {
  const navigation = useNavigation();
    const [intervalTime, setIntervalTime] = useState(7000);
    const [pause,setPause]=useState(false)
    const [imageLoading,setImageLoading]=useState(true)
    const {bannerData } = useContext(AuthContext);


    let bannerDataLoop = bannerData.concat(bannerData)

    let CurrentSlide = 0;
    let Currentoffset = 0;
    const flatList = useRef()
    const flatListRight = useRef()


  useEffect(() => {
    if (pause) {
      let timerId = setInterval(goToNextPage,15000);

      return() => {clearInterval(timerId)};
    } else if (!pause) {
        let timerId = setInterval(goToNextPage,intervalTime);
    return() => clearInterval(timerId); 
    }

      // eslint-disable-next-line
}, [pause]);



//banner things
let bannerRightHeight = width>1000? 85:55

        const goToNextPage = () => {
          setPause(false);
          if (CurrentSlide >= 7) 
          CurrentSlide = 0;
          flatList.current.scrollToIndex({
            index: ++CurrentSlide,
            animated: true,
          }); goToNextPageRight(); 

          
        };

        const goToNextPageRight = () => {
          if (Currentoffset >= 1120) 
          Currentoffset = 0;
          flatListRight.current.scrollToOffset({
           offset:(CurrentSlide*bannerRightHeight)+bannerRightHeight,
            animated: true,
          });
          
        };

        const bannerClick = (index,CurrnetSlide,Currentoffset) => {
          setPause(true)
          let newindex = index > 7? index-8:index;
          CurrnetSlide = newindex;
          Currentoffset = newindex*bannerRightHeight;

          flatList.current.scrollToIndex({
            index: newindex,
            animated: true,
          });

          flatListRight.current.scrollToOffset({
            offset:(newindex*bannerRightHeight)+bannerRightHeight,
             animated: true,
           });          
         

          
          
        };

//banner things ends


  const renderList = ({project,categoryname}) => {



    let newImgURL = Object.keys(project.images)
    .map((a) => ({sort: Math.random(), value: a}))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value)
    
    var imgURL = 'imgURL'+newImgURL[0].slice(newImgURL[0].length-2)
    var item = project
    //console.log('banneritem',item)

        
    const gradientHeight= width < 1001? 200:200;
    const gradientBackground  = 'black';
        const data = Array.from({ length: gradientHeight+1 });
    return (


                <View style={styles.itemFlatlist}>
                            <Image  
                              source={{ uri: project.images[imgURL]  }} 
                              style={styles.bannerImage} 

                              /> 
             

                      <View style={{flex:1}}>
                          {data.map((_, i) => (
                              <View
                                  key={i}
                                  style={{
                                      position: 'absolute',
                                      backgroundColor: gradientBackground,
                                      height: 1,
                                      bottom: (gradientHeight - i),
                                      right: 0,
                                      left: 0,
                                      zIndex: 2,
                                      opacity: (1 / gradientHeight) * (i + 1)
                                  }}
                              />
                          ))}
                      </View>



                        <View style={styles.bannerGradient}>
                       
                        <TouchableOpacity 
                              onPress={() => navigation.navigate('Projects List', {title:categoryname.categoryname,category:'service'})} 
                        >
                            <Text style={styles.bannerText}>{categoryname.categoryname}
                            <Text style={styles.bannerArrow}>{'\u276F'}</Text>
                            </Text>

                        </TouchableOpacity >



                            <Text style={styles.bannerTextSub}>Photo from Project :</Text>

                            <TouchableOpacity 
                                  onPress={() => navigation.navigate('Project Details', {pageData:item})} 
                            >
                            <Text style={styles.bannerTextSubTitle}>{project.title},
                            <Text style={styles.bannerTextSubLocation}>{' '}{project.city},{' '}{project.country}</Text> </Text>
                            <Text style={styles.bannerProjectLink}>See This Project {''}{'\u276F'}
                            </Text>
                            </TouchableOpacity>
                            


                        </View>


      </View>
    )
  }
  
  const renderListRight = ({item,index}) => {
    const {project,categoryname}= item
    let newImgURL = Object.keys(project.images)
    .map((a) => ({sort: Math.random(), value: a}))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value)
    
    var thumbURL = 'thumbURL'+newImgURL[0].slice(newImgURL[0].length-2)
     //console.log('imagelength-',id,title,imagecount,randomid,thumbURL)

    let firstindex= parseInt(index)
    
    return (


                <View style={ styles.itemFlatlistRight}>
                            <Image  
                              source={{ uri: project.images[thumbURL]  }} 
                              style={styles.bannerImageRight} 
                              />              


                      <TouchableOpacity 
                              onPress={() => bannerClick(parseInt(index)) } 
                        >
                        <View style={styles.bannerGradient}>
                            <Text style={styles.bannerTextRight}>{categoryname.categoryname}</Text>
                        </View>
                      </TouchableOpacity >

            




      </View>
    )
  }

  

    return (
      <SafeAreaView  style={styles.pagestyle}>
        <ScrollView 
        style={styles.headerItem}
        >
      <View style={styles.bannerContainer}>
            <View style={styles.bannerContainerLeft}>

                    <FlatList 
                        horizontal={true}
                        //pagingEnabled={true}
                        scrollEnabled={false}
                        showsHorizontalScrollIndicator={false}
                        data={bannerData}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({item}) => renderList(item)}
                        initialNumToRender={20}
                        contentContainerStyle={styles.flatlistcontainer}
                        ref={flatList}


                        />

        </View>
        <View style={styles.bannerright}>


              <FlatList 
                horizontal={false}
                //pagingEnabled={true}
                scrollEnabled={false}
                data={bannerDataLoop}
                keyExtractor={(item, index) => index.toString()}
                renderItem={(item,index) => renderListRight(item,index)}
                initialNumToRender={20}
                contentContainerStyle={styles.flatlistcontainer}
                ref={flatListRight}


                />
                
                      <TouchableOpacity 
                  onPress={() => navigation.navigate('Services List')} 
                  >
                        <View>
                            <Text style={styles.bannerRightButton}> All Services {'\u276F'}</Text>
                        </View>
                      </TouchableOpacity >

        </View>

      </View>
        </ScrollView>
      </SafeAreaView>

    );
  };


  export default Banner;


const styles = StyleSheet.create({
    pagestyle: {
        flex: 1,
    },
    headerItem:
    {
        flex: 1,
        maxWidth: 1000,
        marginTop:10,
    },
    bannerImage: {
      flex:1,
        height: width < 1000 ? 400 : 600,
        //width: width < 1001 ? width : 1000,
        width:width < 1001 ? width*2/3:1000*2/3 ,
        resizeMode: 'cover',
        borderRadius:20,
        
    },
    imageLoader: {
      flex: 1, 
      position: 'absolute', 
      alignSelf: 'center',
      justifyContent:'center'
    },
    bannerGradient: {
        flex: 1, 
        position: 'absolute', 
        bottom: 0, 

        //alignSelf: 'center'
    },

    bannerText: {
        fontSize: width>1000? '2rem': '1.5rem',
        fontFamily:'muli',
        fontWeight: '600',
        color: colours.primaryWhite,
        marginTop: 15,
        paddingStart:15

    },
    bannerTextSub: {
      fontSize: width>1000? '.7rem': '.6rem',
      fontFamily:'muli',
        fontWeight: '400',
        fontStyle:'italic',
        color: colours.primaryGrey,
        marginTop: 5,
        paddingStart:15,
        //marginBottom:10,
    },

    flatlistcontainer: {
        flex: 1,
        //margin:width>height?10:5,
    },



    bannerContainer:{
      flexDirection:'row',
    },
    bannerContainerLeft:{
      flex:2,
    },
    bannerContainerRight:{
      flex:1,

    },

    bannerright:{
      flex:1,
      //backgroundColor:'green',
      maxHeight:width < 1000 ? 400 : 600,
    },

    bannerImageRight: {
      height:  width>1000?50:40,
      width:  width>1000?75:50,
      resizeMode: 'cover',
      marginLeft:width>1000?10:7,
      borderRadius:5,

  },

  bannerTextRight: {
    fontSize: width>1000? '.9rem': '.7rem',
    fontFamily:'muli',
    fontWeight: '600',
    color: colours.primaryWhite,
    marginTop: 15,
    marginLeft:width>1000? 90:60,
    paddingStart:5,


},

bannerRightButton: {
  fontSize: width>1000? '1.3rem': '1rem',
  fontFamily:'muli',
  fontWeight: '600',
  color: colours.brandColor,
  marginTop: 10,
  textAlign:'center',
  justifyContent:'center',
  paddingStart:5,


},
bannerArrow: {
  fontSize: width>1000? '1.4rem': '1.1rem',
  fontFamily:'muli',
  fontWeight: '600',
  color: colours.brandColor,
  textAlign:'center',
  justifyContent:'center',
  borderWidth:1.5,
  borderColor:colours.brandColorDark,
  backgroundColor:'rgba(0,0,0,0.2)',
  borderRadius:5,
  //marginTop: 10,
  marginLeft:15,
  paddingStart:12,
  paddingEnd:9,
},

bannerTextSubTitle: {
  fontSize: width>1000? '.9rem': '.8rem',
  fontFamily:'muli',
    fontWeight: '600',
    fontStyle:'italic',
    color: colours.brandColorLight,
   // marginTop: 5,
    paddingStart:15,
    //marginBottom:10,
},
bannerTextSubLocation: {
  fontSize: width>1000? '.7rem': '.6rem',
  fontFamily:'muli',
    fontWeight: '400',
    fontStyle:'italic',
    color: colours.fifthGrey,
    marginTop: 5,
    paddingStart:5,
    marginBottom:10,
},
bannerProjectLink: {
  fontSize: width>1000? '.8rem': '.7rem',
  fontFamily:'muli',
  fontWeight: '600',
  color: colours.brandColor,
  //textAlign:'center',
  marginHorizontal:5,
  paddingStart:10,
  paddingEnd:9,
},


bannerTextSubRight: {
  fontSize: width>1000? '.7rem': '.5rem',
  fontFamily:'muli',
    fontWeight: '600',
    color: colours.primaryWhite,
    marginTop: 5,
    marginLeft:50,
    paddingStart:5,
    marginBottom:10,
},

itemFlatlistRight: {
  backgroundColor:colours.secondaryBlack,
  height:width>1000? 85:55,
  width:'100%',
  justifyContent:'center',
  borderBottomWidth:2,
  borderBottomColor:colours.primaryBlack,
  
}

});
  

  
  


