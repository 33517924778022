import React, {useContext } from 'react';
import { FlatList,ScrollView,StyleSheet,View, Image, Text, TouchableOpacity, Dimensions,SafeAreaView,Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AuthContext } from '../providers/dataProvider'

import colours from '../config/colours';
import "typeface-muli";

const { height, width } = Dimensions.get('window')
const Featured = () => {
  const navigation = useNavigation();

  const {featuredData } = useContext(AuthContext);




  const renderList = ({id, title,location,city,country,client,description,services,category,images}) => {

    let newImgURL = Object.keys(images)
    .map((a) => ({sort: Math.random(), value: a}))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value)
    
    var thumbURL = 'thumbURL'+newImgURL[0].slice(newImgURL[0].length-2)

            
    return (
      <TouchableOpacity 
      onPress={() => navigation.navigate('Project Details', {pageData:{id, title,location,city,country,client,description,services,category,images}})} 
>

                <View style={styles.itemFlatlist}>
                            <Image  
                              source={{ uri: images[thumbURL]  }} 
                              style={styles.bannerImage} 
                              />              



                        <View style={styles.bannerGradient}>
                            <Text numberOfLines={2} style={styles.bannerText}>{title}</Text>
                            <Text numberOfLines={2}  style={styles.bannerTextSub}>{city}</Text>

                        </View>


      </View>

      </TouchableOpacity >

    )
  }


    return (
      <SafeAreaView  style={styles.pagestyle}>
        <ScrollView 
        style={styles.headerItem}
        >


                    <FlatList 
                        horizontal={true}
                        //scrollEnabled={false}
                        showsHorizontalScrollIndicator={false}
                        data={featuredData}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({item}) => renderList(item)}
                        initialNumToRender={5}
                        contentContainerStyle={styles.flatlistcontainer}
                        />

        </ScrollView>
      </SafeAreaView>

    );
  };


  export default Featured;


const styles = StyleSheet.create({
    pagestyle: {
        flex: 1,
    },
    headerItem:
    {
        flex: 1,
        maxWidth: 1000,
        marginTop:2,
    },
    bannerImage: {
        height: width<1000?140:200,
        width: width<1000?100:150,
        resizeMode: 'cover',
        borderRadius:10,

    },
    bannerGradient: {
        flex: 1, 
        //position: 'absolute', 
        //bottom: 0, 

        //alignSelf: 'center'
    },

    bannerText: {
        fontSize: width>1000? '.8rem': '.7rem',
        fontFamily:'muli',
        fontWeight: '700',
        color: colours.brandColor,
        marginTop: 15,
        //paddingStart:15

    },
    bannerTextSub: {
      fontSize: width>1000? '.7rem': '.6rem',
      fontFamily:'muli',
        fontWeight: '500',
        color: colours.primaryGrey,
        marginTop: 5,
        //paddingStart:15,
        marginBottom:10,
    },

    flatlistcontainer: {
        flex: 1,
        justifyContent:'center'
        //margin:width>height?10:5,
    },
    itemFlatlist:
    {
        flex: 1,
        height: width < 1001 ? 240 : 300,
        marginTop:2,
        marginHorizontal:10,
        padding:10,
        backgroundColor:colours.primaryBlack,
        borderRadius:10,
        alignSelf:'center',
        maxWidth:width<1000?120:170
    },

    bannerContainer:{
      flexDirection:'row',
    },
    bannerContainerLeft:{
      flex:2,
    },
    bannerContainerRight:{
      flex:1,
    },

    bannerright:{
      flex:1,
      backgroundColor:'green'
    },

    bannerImageRight: {
      height: 60,
      width: 50,
      resizeMode: 'cover',
      marginLeft:10,
  },

  bannerTextRight: {
    fontSize: width>1000? '.9rem': '.7rem',
    fontFamily:'muli',
    fontWeight: '800',
    color: colours.primaryWhite,
    marginTop: 15,
    marginLeft:65,
    paddingStart:5,


},
bannerTextSubRight: {
  fontSize: width>1000? '.7rem': '.5rem',
  fontFamily:'muli',
    fontWeight: '600',
    color: colours.primaryWhite,
    marginTop: 5,
    marginLeft:65,
    paddingStart:5,
    marginBottom:10,

},
itemFlatlistRight: {
  backgroundColor:colours.primaryBlack,
  height:70,
  width:'100%',
}

});
  

  
  


