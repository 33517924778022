// Colours
const colours = {
  brandColor:'#a1c52b',
  brandColorLight:'#cef354',
  brandColorDark: '#8daf1c',

  brandColorExtraDark: '#7a9817',


  primaryBlack: '#101010',
  secondaryBlack: '#1D1D1D',
  tertiaryBlack: '#141414',
  highlightBlack: '#0B0B0B',
  primaryWhite: '#fff',
  primaryTeal: '#07CCBA',
  primaryGrey: '#AAAAAA',
  //secondaryGrey: '#3E3E3E', 
  secondaryGrey:'#4d4d4d',
  firstGrey: '#212121',
 // secondGrey: '#424242',
  secondGrey: '#333333',
  thirdGrey: '#616161',
  forthGrey: '#757575',
  fifthGrey:'#9e9e9e',
  //New Set

};
export default colours;
