

import React, {useState} from "react";
import { View, Text, Image,TextInput, ImageBackground, Dimensions,Button, StyleSheet, ScrollView,TouchableOpacity,Linking } from "react-native";
//import { TouchableOpacity } from "react-native-gesture-handler";
//import Clipboard from "@react-native-community/clipboard";
//import Button from '../components/Button';
import colours from '../config/colours'
//import Icon from 'react-native-vector-icons/Ionicons'
import "typeface-muli";
import { firebase } from '../config/firebase'


import WhatsApp from '../assets/social/Whatsapp.svg';
import Email from '../assets/social/Email.svg';



const { height, width } = Dimensions.get('window')
if(height<width){
    var widthNew=height;
    var heightNew=width;
  } else{
    var widthNew=width;
    var heightNew=height;
  }

  const Contact = ({navigation, route, hashtags, onPressHandle, thumbURL, imgURL, toggleModal}) => {


    const [onFocus, setOnFocus ]= useState(false)
    const [onBlur, setOnBlur ]= useState(false)
  
    const [onFocusNumber, setOnFocusNumber ]= useState(false)
    const [onBlurNumber, setOnBlurNumber ]= useState(false)
  
    const [onFocusMessage, setOnFocusMessage ]= useState(false)
    const [onBlurMessage, setOnBlurMessage ]= useState(false)
  
    const [nameText, setNameText ]= useState('')
    const [numberText, setNumberText ]= useState('')
    const [messageText, setMessageText ]= useState('')
  
    const [subHeaderTextHeight, setSubHeaderTextHeight ]= useState(0)
    const whatsappURL = 'https://api.whatsapp.com/send?phone=971503773805'


    const contactUsData=() => {

      var currentDate = new Date();
      var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
      var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
      var createdDateTo = currentDate.getFullYear() + twoDigitMonth + twoDigitDate; 
      const docid = createdDateTo+"-"+new Date().getTime()

      
  
    firebase.firestore().collection('messages')
    .doc(docid).set({
      name: nameText,
      number: numberText,
      createdDtm:  new Date(),
      message: messageText
    })
    .then(alert('Sent Successfully, We will contact you as soon as possible. Thank You'))
    .then(setNameText(''),setNumberText(''),setMessageText(''))
  
  
  
    }
    return(

    <View style={styles.modalContainer}>

<Text style={styles.textHeader}>CONTACT US</Text>
            <Text style={styles.textSubHeader}>Get In Touch</Text>



            <View style={styles.textContainer}>
            <TextInput 
                                adjustsFontSizeToFit={true}  
                                allowFontScaling={true} 
                               // autoFocus={true}
                                value={nameText}
                                placeholder={onFocus?'':'Name'}
                                //multiline={false}
                                //editable={true}
                                //autoCorrect={true}
                                //autoCapitalize='none'
                                //onContentSizeChange={(event) => {setSubHeaderTextHeight(event.nativeEvent.contentSize.height)}}
                                onChangeText={(editedText) => {setNameText(editedText)}}
                                onFocus={()=> {setOnFocus(true),setOnBlur(false)}}
                                onBlur={()=> {setOnBlur(true),setOnFocus(false)}}
                                onKeyPress={(nativeEvent ) => {
                                  if (nativeEvent.key === 'Backspace') {
                                    //setSubHeaderTextHeight(0) 
                                  }}}
                                  placeholderTextColor={colours.forthGrey}
                                style={{
                                  minWidth:'40vh',
                                  maxWidth:500,

                                  textAlign:'left',
                                  fontFamily: 'muli',
                                  marginBottom:10,
                                  marginTop:2,
                                  color: 'white', 
                                  fontSize: '.8rem', 
                                  fontWeight:'500',
                                  borderWidth:1,
                                  borderColor:onFocus?colours.brandColorExtraDark:colours.forthGrey,
                                  borderRadius:10,
                                  padding:10
                                  
                                }}
                                  />
                          <TextInput 
                                adjustsFontSizeToFit={true}  
                                allowFontScaling={true} 
                               // autoFocus={true}
                                value={numberText}
                                placeholder={onFocusNumber?'':'Contact/WhatsApp No'}
                                keyboardType='numeric'
                                maxLength={15}

                                //multiline={false}
                                //editable={true}
                                //autoCorrect={true}
                                //autoCapitalize='none'
                                //onContentSizeChange={(event) => {setSubHeaderTextHeight(event.nativeEvent.contentSize.height)}}
                                onChangeText={(editedText) => {setNumberText(editedText)}}
                                onFocus={()=> {setOnFocusNumber(true),setOnBlurNumber(false)}}
                                onBlur={()=> {setOnBlurNumber(true),setOnFocusNumber(false)}}
                                onKeyPress={(nativeEvent ) => {
                                  if (nativeEvent.key === 'Backspace') {
                                    //setSubHeaderTextHeight(0) 
                                  }}}
                                  placeholderTextColor={colours.forthGrey}

                                style={{
                                  minWidth:'40vh',
                                  maxWidth:500,

                                  textAlign:'left',
                                  fontFamily: 'muli',
                                  marginBottom:10,
                                  marginTop:2,
                                  color: 'white', 
                                  fontSize: '.8rem', 
                                  fontWeight:'500',
                                  borderWidth:1,
                                  borderColor:onFocusNumber?colours.brandColorExtraDark:colours.forthGrey,
                                  borderRadius:10,
                                  padding:10
                                  
                                }}
                                  />
                          <TextInput 
                                adjustsFontSizeToFit={true}  
                                allowFontScaling={true} 
                               // autoFocus={true}
                                value={messageText}
                                placeholder={onFocusMessage?'':'Message'}
                                multiline={true}
                                //editable={true}
                                //autoCorrect={true}
                                //autoCapitalize='none'
                                onContentSizeChange={(event) => {setSubHeaderTextHeight(event.nativeEvent.contentSize.height)}}
                                onChangeText={(editedText) => {setMessageText(editedText)}}
                                onFocus={()=> {setOnFocusMessage(true),setOnBlurMessage(false)}}
                                onBlur={()=> {setOnBlurMessage(true),setOnFocusMessage(false)}}
                                onKeyPress={(nativeEvent ) => {
                                  if (nativeEvent.key === 'Backspace') {
                                    //setSubHeaderTextHeight(0) 
                                  }}}
                                  placeholderTextColor={colours.forthGrey}

                                style={{
                                  minWidth:'40vh',
                                  maxWidth:500,

                                  textAlign:'left',
                                  fontFamily: 'muli',
                                  marginBottom:2,
                                  marginTop:2,
                                  color: 'white', 
                                  fontSize: '.8rem', 
                                  fontWeight:'500',
                                  borderWidth:1,
                                  borderColor:onFocusMessage?colours.brandColorExtraDark:colours.forthGrey,
                                  borderRadius:10,
                                  padding:10,
                                  height: Math.max(20, subHeaderTextHeight),
                                  minHeight: Math.max(20, 100)


                                  
                                }}
                                  />

<TouchableOpacity
    onPress={() => {
      isNaN(numberText)?alert('Please Provide Contact Number'):
      nameText.length<2?alert('Please Provide Name'):
      numberText.length<5?alert('Please Provide Contact Number'):

      messageText.length<3?alert('Please Let us know your Message') : contactUsData()

    }
    }
  >
    <View style={styles.submitButton}>
      <Text style={styles.emailText}>Submit</Text>
    </View>
  </TouchableOpacity>
            </View>







        <View style={styles.yearBoxRow}>

        <TouchableOpacity 
                onPress={() => Linking.openURL('mailto:info@pdc-interiors.com?subject=Support&body=Hi')}

                    >
                <View style={styles.socialBox}>
                  <img src={Email} width={55} />
                </View>
                </TouchableOpacity>

                <TouchableOpacity 
                 onPress={() => Linking.openURL(whatsappURL)}
                 >
                <View style={styles.socialBox}>
                  <img src={WhatsApp} width={55} />
                </View>
                </TouchableOpacity>

        </View>

                <View style={styles.closeButtonContainer}>
                    <TouchableOpacity
                    onPress={toggleModal} 
                    >
                        <View style={styles.closeContainer}>
                            <Text style={styles.closeText}>Go Back</Text>
                        </View>
                    </TouchableOpacity>
                </View>

            </View>

    )}

export default Contact;

const styles = StyleSheet.create({

  modalContainer:{
    flex:1,
   // marginHorizontal:widthNew*.05, 
   // marginVertical:heightNew*.05,
    borderRadius:25,
    backgroundColor:colours.primaryBlack,      
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
    paddingBottom:40,
  },

  textHeader: {
    marginVertical:20,
    marginHorizontal:10,
    color:colours.fifthGrey,
    fontSize:30,
    fontWeight:'700'
    },    
    textSubHeader: {
      marginVertical:5,
      marginHorizontal:20,
      color:colours.thirdGrey,
      fontSize:15,
      fontWeight:'700'
      },

      textContainer: {
        marginTop:10,
        marginHorizontal:10,
        padding:10,
        marginBottom:10,
        backgroundColor: colours.tertiaryBlack,
        borderRadius:25,
        //height:height/3,
        justifyContent:'center',
        alignItems:'center',
        },
  

      textEmail: {
        marginVertical:5,
        marginHorizontal:20,
        color:colours.primaryGrey,
        fontSize:12,
        },
    text: {
      marginVertical:5,
      marginHorizontal:20,
      color:'grey',
      fontSize:12,
      },

      emailButtonContainer: {
        flex:1,
        alignItems:'center',
        marginVertical:10,
      },

      emailContainer: {
        backgroundColor: colours.brandColorExtraDark,
        alignItems:'center',
        borderRadius:20,
        paddingHorizontal:50,
        paddingVertical:20,
      },

    emailText: {
        fontSize: 20,
        color: colours.brandColorLight,
      },
    categoryTitleText: {
        fontSize: 20,
        color: colours.primaryGrey,
        marginTop:2,
        //paddingTop:10,
      },




      closeContainer: {
        backgroundColor: colours.brandColorExtraDark,
        alignItems:'center',
        borderRadius:20,
        paddingHorizontal:20,
        paddingVertical:5,
        marginVertical:10,
      },
      closeButtonContainer: {
        flex:1,
        alignItems:'center',
        marginVertical:20,
      },
      closeText: {
        fontSize: '1rem',
        color: colours.primaryWhite,
        paddingVertical:10,
      },

      submitButton: {
        backgroundColor: colours.brandColorExtraDark,
        alignItems: 'center',
        borderRadius: 20,
        paddingHorizontal:20,
        paddingVertical: 10,
        marginTop:10,
      },
      socialBox: {
        //flex: 4,
        //paddingHorizontal:15,
        justifyContent: 'center',
        marginHorizontal: 5,
        marginVertical: 5,
      },  yearBoxRow: {
        marginVertical: 5,
        height:45,
        flexDirection: 'row',
        justifyContent: 'center',
    
      },

        

});


