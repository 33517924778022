import React from 'react';
import HomeNavigator from './pages/homeNavigator';

const App = () => {
      return (
            <HomeNavigator />
      )
}
export default App ;


