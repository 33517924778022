import React, {useContext } from 'react';
import { FlatList,ScrollView,StyleSheet,View, Image, Text, TouchableOpacity, Dimensions,SafeAreaView,Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AuthContext } from '../providers/dataProvider'

import colours from '../config/colours';
import "typeface-muli";

const { height, width } = Dimensions.get('window')
const Projects = () => {
  const navigation = useNavigation();
  const {categoriesData,serviceAllData } = useContext(AuthContext);

  const renderList = ({item,index}) => {
    const {project,categoryname}= item

    let newImgURL = Object.keys(project.images)
    .map((a) => ({sort: Math.random(), value: a}))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value)
    
    var thumbURL = 'thumbURL'+newImgURL[0].slice(newImgURL[0].length-2)

    return (
      <TouchableOpacity 
      onPress={() => navigation.navigate('Projects List', {title:categoryname.categoryname,category:'category'})} 
      >

                <View style={styles.itemFlatlist}>
                            <Image  
                              source={{ uri: project.images[thumbURL]  }} 
                              style={styles.bannerImage} 
                              />              



                        <View style={styles.bannerGradient}>
                            <Text numberOfLines={2} style={styles.bannerText}>{categoryname.categoryname.toUpperCase()}{' '}{'\u276F'}</Text>
                        </View>


      </View>

      </TouchableOpacity >

    )
  }


    return (
      <SafeAreaView  style={styles.pagestyle}>
        <ScrollView 
        style={styles.headerItem}
        >


                    <FlatList 
                        //horizontal={true}
                        //scrollEnabled={false}
                        data={categoriesData}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={(item,index) => renderList(item,index)}
                        initialNumToRender={10}
                        numColumns={width>1000?4:2}
                        contentContainerStyle={styles.flatlistcontainer}
                        />

        </ScrollView>
      </SafeAreaView>

    );
  };


  export default Projects;


const styles = StyleSheet.create({
    pagestyle: {
        flex: 1,
    },
    headerItem:
    {
        flex: 1,
        maxWidth: 1000,
        marginTop:2,
    },


    flatlistcontainer: {
      flex: 1,
      alignItems:'center',
      justifyContent:'space-between'
      
      //margin:width>height?10:5,
  },
  itemFlatlist:
    {
        flex: 1,
        //height: width < 1001 ? 200 : 200,
        //width:width < 1001 ? 200 : 220,
        alignItems:'center',
        marginTop:20,
        marginHorizontal:5,
        padding:10,
        backgroundColor:colours.primaryBlack,
        borderRadius:10,
    },


    bannerImage: {
        height: width<1000?120:120,
        width: width<1000?150:150,
        resizeMode: 'cover',
        borderRadius:10,

    },


    bannerGradient: {
        flex: 1, 
        //position: 'absolute', 
        //bottom: 0, 

        //alignSelf: 'center'
    },

    bannerText: {
        fontSize: width>1000? '1.1rem': '1rem',
        fontFamily:'muli',
        fontWeight: '800',
        color: colours.primaryGrey,
        marginTop: 20,
        //paddingStart:15

    },
});
  

  
  


