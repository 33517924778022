import React from 'react';
import { StyleSheet,View, Text, Dimensions,ScrollView,SafeAreaView } from 'react-native';

import 'firebase/analytics'

import colours from '../config/colours';
import Header from '../components/header'
import Featured from '../components/featured'

import Footer from '../components/footer'
import Projects from '../components/projects';
import ProjectsByServices from '../components/projectsByServices';



const { height, width } = Dimensions.get('window')


const ProjectsPage = ({navigation}) => {
    

    return (
      <SafeAreaView>
        <ScrollView>
      <View style={styles.safeView}>

 <Header />




                <View style={styles.categoryTitle}>
                <Text numberOfLines={1} style={styles.featuredTodayText} >
                      Projects by Sectors
                </Text>
                </View>


                <View style={styles.videoBoxRow}>

<Projects />

                </View>




                <View style={styles.categoryTitle}>
                <Text numberOfLines={1} style={styles.featuredTodayText} >
                      Projects by Services
                </Text>
                </View>


                <View style={styles.videoBoxRow}>

<ProjectsByServices />

                </View>




<Text numberOfLines={1} style={styles.featuredTodayText} >
                                Featured  Today
 </Text>
<Featured />








<Footer />



      </View>
      </ScrollView>
      </SafeAreaView>

    );
  };



const styles = StyleSheet.create({
  safeView: {
    flex: 1,    
    maxWidth:width>1000?1000:width,
    minWidth:width>1000? 900:width,
    alignSelf:'center'
  },


  categoryContainer: {
    flexDirection: 'row',
    marginHorizontal: 10,
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomColor: colours.secondGrey,
  },


  categoryTitle: {
    flex: 1,
    //justifyContent:'flex-start',
    justifyContent: 'center',
    marginVertical: 5,

  },
  projectSeeAll: {
    justifyContent: 'flex-end',
    borderWidth: 0.5,
    paddingHorizontal: 15,
    paddingBottom: 10,
    borderRadius: 10,
  },

  bannerArrow: {
    fontSize: width>1000? '1.2rem': '1rem',
    fontFamily:'muli',
    fontWeight: '600',
    color: colours.brandColor,
    textAlign:'center',
    justifyContent:'center',
    borderWidth:1.5,
    borderColor:colours.brandColorDark,
    backgroundColor:'rgba(0,0,0,0.2)',
    borderRadius:5,
    //marginTop: 10,
    marginLeft:15,
    paddingStart:12,
    paddingEnd:9,
    paddingVertical:5,
  },
  
  featuredTodayText: {
    marginTop:50,
    marginBottom:10,
    fontSize: '2rem',
    paddingLeft:5,
    color: colours.brandColor,
   // textAlign: 'center',
    fontWeight: '700',
    fontFamily:'muli'
  },



  videoBoxRow: {
    margin: 5,
    flexDirection: 'row',
    justifyContent: 'center',

  },


});
  

export default ProjectsPage;
  
  


