import React, {useState,useContext} from 'react';
import { StyleSheet,View,Button, Alert, Text, TextInput,TouchableOpacity, Dimensions,Linking,FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView } from 'react-native';
import Modal from 'react-native-modal';

import { firebase } from '../config/firebase'
import 'firebase/analytics'
import { AuthContext } from '../providers/dataProvider';
import WhatsApp from '../assets/social/Whatsapp.svg';
import Email from '../assets/social/Email.svg';


import colours from '../config/colours';
import Header from '../components/header'
import Banner from '../components/banner'
import Featured from '../components/featured'

import Footer from '../components/footer'
import Services from '../components/services';
import Projects from '../components/projects';
import "typeface-muli";



const { height, width } = Dimensions.get('window')
    const whatsappURL = 'https://api.whatsapp.com/send?phone=971503773805'


const HomeScreen = ({navigation}) => {
    
  const [onFocus, setOnFocus ]= useState(false)
  const [onBlur, setOnBlur ]= useState(false)

  const [onFocusNumber, setOnFocusNumber ]= useState(false)
  const [onBlurNumber, setOnBlurNumber ]= useState(false)

  const [onFocusMessage, setOnFocusMessage ]= useState(false)
  const [onBlurMessage, setOnBlurMessage ]= useState(false)

  const [nameText, setNameText ]= useState('')
  const [numberText, setNumberText ]= useState('')
  const [messageText, setMessageText ]= useState('')

  const [subHeaderTextHeight, setSubHeaderTextHeight ]= useState(0)
  const whatsappURL = 'https://api.whatsapp.com/send?phone=971503773805'


  const contactUsData=() => {
    var currentDate = new Date();
    var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
    var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var createdDateTo = currentDate.getFullYear() + twoDigitMonth + twoDigitDate; 
    const docid = createdDateTo+"-"+new Date().getTime()

  firebase.firestore().collection('messages')
  .doc(docid).set({
    name: nameText,
    number: numberText,
    createdDtm:  new Date(),
    message: messageText
  })
  .then(alert('Sent Successfully, We will contact you as soon as possible. Thank You'))
  .then(setNameText(''),setNumberText(''),setMessageText(''))



  }


  
    return (
      <SafeAreaView>
        <ScrollView>
      <View style={styles.safeView}>

 <Header />
 <View style={styles.modalContainer}>

<Text style={styles.textHeader}>CONTACT US</Text>
<Text style={styles.textSubHeader}>Get In Touch</Text>

<View style={styles.textContainer}>

  <TextInput 
                                adjustsFontSizeToFit={true}  
                                allowFontScaling={true} 
                               // autoFocus={true}
                                value={nameText}
                                placeholder={onFocus?'':'Name'}
                                //multiline={false}
                                //editable={true}
                                //autoCorrect={true}
                                //autoCapitalize='none'
                                //onContentSizeChange={(event) => {setSubHeaderTextHeight(event.nativeEvent.contentSize.height)}}
                                onChangeText={(editedText) => {setNameText(editedText)}}
                                onFocus={()=> {setOnFocus(true),setOnBlur(false)}}
                                onBlur={()=> {setOnBlur(true),setOnFocus(false)}}
                                onKeyPress={(nativeEvent ) => {
                                  if (nativeEvent.key === 'Backspace') {
                                    //setSubHeaderTextHeight(0) 
                                  }}}
                                  placeholderTextColor={colours.forthGrey}
                                style={{
                                  minWidth:'40vh',
                                  maxWidth:500,

                                  textAlign:'left',
                                  fontFamily: 'muli',
                                  marginBottom:10,
                                  marginTop:2,
                                  color: 'white', 
                                  fontSize: '.8rem', 
                                  fontWeight:'500',
                                  borderWidth:1,
                                  borderColor:onFocus?colours.brandColorExtraDark:colours.forthGrey,
                                  borderRadius:10,
                                  padding:10
                                  
                                }}
                                  />
                          <TextInput 
                                adjustsFontSizeToFit={true}  
                                allowFontScaling={true} 
                               // autoFocus={true}
                                value={numberText}
                                placeholder={onFocusNumber?'':'Contact/WhatsApp No'}
                                keyboardType='numeric'
                                maxLength={15}

                                //multiline={false}
                                //editable={true}
                                //autoCorrect={true}
                                //autoCapitalize='none'
                                //onContentSizeChange={(event) => {setSubHeaderTextHeight(event.nativeEvent.contentSize.height)}}
                                onChangeText={(editedText) => {setNumberText(editedText)}}
                                onFocus={()=> {setOnFocusNumber(true),setOnBlurNumber(false)}}
                                onBlur={()=> {setOnBlurNumber(true),setOnFocusNumber(false)}}
                                onKeyPress={(nativeEvent ) => {
                                  if (nativeEvent.key === 'Backspace') {
                                    //setSubHeaderTextHeight(0) 
                                  }}}
                                  placeholderTextColor={colours.forthGrey}

                                style={{
                                  minWidth:'40vh',
                                  maxWidth:500,

                                  textAlign:'left',
                                  fontFamily: 'muli',
                                  marginBottom:10,
                                  marginTop:2,
                                  color: 'white', 
                                  fontSize: '.8rem', 
                                  fontWeight:'500',
                                  borderWidth:1,
                                  borderColor:onFocusNumber?colours.brandColorExtraDark:colours.forthGrey,
                                  borderRadius:10,
                                  padding:10
                                  
                                }}
                                  />
                          <TextInput 
                                adjustsFontSizeToFit={true}  
                                allowFontScaling={true} 
                               // autoFocus={true}
                                value={messageText}
                                placeholder={onFocusMessage?'':'Message'}
                                multiline={true}
                                //editable={true}
                                //autoCorrect={true}
                                //autoCapitalize='none'
                                onContentSizeChange={(event) => {setSubHeaderTextHeight(event.nativeEvent.contentSize.height)}}
                                onChangeText={(editedText) => {setMessageText(editedText)}}
                                onFocus={()=> {setOnFocusMessage(true),setOnBlurMessage(false)}}
                                onBlur={()=> {setOnBlurMessage(true),setOnFocusMessage(false)}}
                                onKeyPress={(nativeEvent ) => {
                                  if (nativeEvent.key === 'Backspace') {
                                    //setSubHeaderTextHeight(0) 
                                  }}}
                                  placeholderTextColor={colours.forthGrey}

                                style={{
                                  minWidth:'40vh',
                                  maxWidth:500,

                                  textAlign:'left',
                                  fontFamily: 'muli',
                                  marginBottom:10,
                                  marginTop:2,
                                  color: 'white', 
                                  fontSize: '.8rem', 
                                  fontWeight:'500',
                                  borderWidth:1,
                                  borderColor:onFocusMessage?colours.brandColorExtraDark:colours.forthGrey,
                                  borderRadius:10,
                                  padding:10,
                                  height: Math.max(20, subHeaderTextHeight),
                                  minHeight: Math.max(20, 100)


                                  
                                }}
                                  />

<TouchableOpacity
    onPress={() => {
      isNaN(numberText)?alert('Please Provide Contact Number'):
      nameText.length<2?alert('Please Provide Name'):
      numberText.length<5?alert('Please Provide Contact Number'):

      messageText.length<3?alert('Please Let us know your Message') : contactUsData()

    }
    }
  >
    <View style={styles.submitButton}>
      <Text style={styles.emailText}>Submit</Text>
    </View>
  </TouchableOpacity>

</View>






<View style={styles.yearBoxRow}>

        <TouchableOpacity 
                onPress={() => Linking.openURL('mailto:info@pdc-interiors.com?subject=Support&body=Hi')}

                    >
                <View style={styles.socialBox}>
                  <img src={Email} width={45} />
                </View>
                </TouchableOpacity>

                <TouchableOpacity 
                 onPress={() => Linking.openURL(whatsappURL)}
                 >
                <View style={styles.socialBox}>
                  <img src={WhatsApp} width={45} />
                </View>
                </TouchableOpacity>

        </View>
        <Text style={{ textAlign: 'center', color: colours.primaryGrey }}>{'\n'}Email   info@pdc-interiors.com</Text>


</View>


<Footer />



      </View>
      </ScrollView>
      </SafeAreaView>

    );
  };



const styles = StyleSheet.create({
  safeView: {
    flex: 1,    
    maxWidth:width>1000?1000:width,
    minWidth:width>1000? 900:width,
    alignSelf:'center',
    minHeight: '100vh'
  },
 
  modalContainer: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 20,
  },


  textHeader: {
    marginVertical: 20,
    marginHorizontal: 10,
    color: colours.fifthGrey,
    fontSize: 30,
    fontWeight: '700'
  },
  textSubHeader: {
    marginVertical: 5,
    marginHorizontal: 20,
    color: colours.thirdGrey,
    fontSize: 15,
    fontWeight: '700'
  },

  textContainer: {
    marginTop: 20,
    marginHorizontal: 10,
    padding: 50,
    marginBottom: 10,
    backgroundColor: colours.tertiaryBlack,
    borderRadius: 25,
    //height:height/3,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth:'50vh'
  },
  textEmail: {
    marginVertical: 5,
    marginHorizontal: 20,
    color: colours.primaryGrey,
    fontSize: 12,
  },
  emailButtonContainer: {
    flex: 1,
    alignItems: 'center',
    marginVertical: 10,
  },

  emailText: {
    fontSize: '1rem',
    color: colours.primaryWhite,
  },

  emailContainer: {
    backgroundColor: colours.brandColorExtraDark,
    alignItems: 'center',
    borderRadius: 20,
    paddingHorizontal:20,
    paddingVertical: 20,
    //width: 400,
  },
 
  submitButton: {
    backgroundColor: colours.brandColorExtraDark,
    alignItems: 'center',
    borderRadius: 20,
    paddingHorizontal:20,
    paddingVertical: 10,
    marginTop:10,
  },

  socialBox: {
    //flex: 4,
    //paddingHorizontal:15,
    justifyContent: 'center',
    marginHorizontal: 5,
    marginVertical: 5,
  }, 

  yearBoxRow: {
    marginVertical: 5,
    height:55,
    flexDirection: 'row',
    justifyContent: 'center',

  },

});
  

export default HomeScreen;
  
  


