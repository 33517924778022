import React, { useState,useEffect, useRef } from 'react';
import { ScrollView,Animated, Easing, TouchableWithoutFeedback, View, Text, Dimensions, StyleSheet,TouchableOpacity,Linking } from "react-native";
import menuIcon from '../assets/images/menu.svg';
import WhatsApp from '../assets/social/Whatsapp.svg';

import colours from '../config/colours';
import "typeface-muli";
const { height, width } = Dimensions.get('window')


const AccordionListItem = ({ title, children }) => {


  const [open, setOpen] = useState(false);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState();

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  });

  const toggleListItem = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    }
    setOpen(!open); 
  };
  const whatsappURL = 'https://api.whatsapp.com/send?phone=971503773805'

  return (
    <>
        <View style={styles.titleContainer}>


                  <Text>{title}</Text>

                  <View style={styles.rightContainer}>
                          <View style={{marginHorizontal:15, justifyContent:'center'}}>
                                <TouchableOpacity
                                onPress={() => Linking.openURL(whatsappURL)}

                                >
                              <img src={WhatsApp} width={35} />
                              </TouchableOpacity>
                          </View>
                          <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
                          <TouchableOpacity onPress={() => toggleListItem()}>

                            {!open &&
                                        <View style={styles.menuLogo}>
                                        <img src={menuIcon} width={40} />
                                        <Text numberOfLines={1} style={styles.categoryMenuText} >MENU</Text>
                                        </View>

                            }
                            {open &&
                                        <View style={styles.closeContainer}>
                                        <Text style={styles.closeText}>X</Text>
                                    </View>
                            }
                                  </TouchableOpacity>
                          </Animated.View>
                  </View>


          
        </View>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
        <View
          style={styles.bodyContainer}
          onLayout={event =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }>
          {children}
        </View>
      </Animated.View>
    </>
  );
};
  
    export default AccordionListItem;


    const styles = StyleSheet.create({
      bodyBackground: {
        //backgroundColor: '#EFEFEF',
        overflow: 'hidden',
      },
      titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingEnd:5,

      },
      bodyContainer: {
        flex:1,
       //alignItems:'flex-end',
        position: 'absolute',
        bottom: 0,
        //right:0,
       width:'100%'


      },
      rightContainer: {
        flexDirection:'row',
      },
      menuLogo: {
       // justifyContent:'center',
        //paddingHorizontal:12,
        //paddingVertical:9,
      },
    
      closeContainer: {
        alignItems:'center',
        paddingHorizontal:12,
        paddingVertical:13,
      },
    
      closeText: {
        fontSize: '1.5rem',
        fontFamily:'muli',
        color: 'red',
        fontWeight:500,
      },
      categoryMenuText: {
        fontSize: 11,
        fontWeight: '500',
        textAlign: 'center',
        color: colours.brandColorLight,
      },
    });
