
import 'react-native-gesture-handler';
import React from 'react';

import { Platform,StyleSheet,View} from 'react-native';


import { NavigationContainer, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import Home from '../providers/home' 


const HomeStack = createStackNavigator();
function HomeStackScreen({route, navigation}) {


  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false}}>
      <HomeStack.Screen name='Home' component={Home} options={{headerShown: false}}/>

   </HomeStack.Navigator>
  );
}

const linking={enabled: true }

function HomeNavigator() {
  
  return (
    <View style={styles.navContainer}>
    <NavigationContainer linking={linking} theme={DarkTheme}>
      <HomeStackScreen />
    </NavigationContainer>
    </View>
    
  )
}

export default HomeNavigator;
const styles = StyleSheet.create({ 
  navContainer: { 
    ...Platform.select({ 
      native: { flex: 1, }, 
      web: { height: '100vh', }, 
    }), 
    }
  })